import { showErrorToast } from "./toast";
import { EBUploadManager } from "./uploads";

export function setupTinyMCEEditors(baseElement, file_picker_callback = undefined) { //opts = {}) {
    try {
        baseElement.querySelectorAll(`textarea:not(.nowysiwyg)`).forEach(element => {
            try {
                tinymce.init({
                    target: element,
                    plugins: [
                        ...(element.getAttribute("tinymce-plugins") || "advlist,autolink,lists,link,image,charmap,preview,anchor,searchreplace,visualblocks,code,fullscreen,insertdatetime,media,table,help").split(","),
                        ...["help", "code", "autoresize"], //, "ebnonedit"]
                        ... ["eb-variables"]
                    ],
                    //ebpluginsinfo: element.getAttribute("data-tinymce-variables"), data-tinymce-variables="My Friendly Name|registrant.name||Event ID|event.id"
                    toolbar: element.getAttribute("tinymce-toolbar") || "blocks bold italic backcolor alignleft aligncenter alignright alignjustify bullist numlist outdent indent removeformat code eb-variables",
                    //skin: document.body.classList.contains("dark") ? "tinymce-5-dark" : "",
                    //content_css: document.body.classList.contains("dark") ? "dark" : "",
                    content_style: `.mceNonEditable { border: dashed 1px rgb(144, 164, 174); }`,
                    //noneditable_regexp: /<eb-.+?>(.+?)<\/eb-.+?>/g,
                    noneditable_regexp: /<placeholderdonotremove>(.+?)<\/placeholderdonotremove>/g,
                    promotion: false,
                    branding: false, //https://www.tiny.cloud/docs/configure/editor-appearance/#branding
                    inline: false, //element.tagName == "DIV",
                    readonly: element.hasAttribute("disabled") || element.hasAttribute("readonly"),
                    menubar: element.getAttribute("tinymce-menu") === "true",
                    valid_elements: "*[*]",
                    valid_children: "*[*]",
                    forced_root_block: "p",
                    ui_mode: "split",
                    file_picker_callback: file_picker_callback,
                    statusbar: false,
                    elementpath: false,
                    allow_script_urls: true,
                    convert_urls: false,
                    link_default_target: "_blank",
                    link_assume_external_targets: true,
                    //paste_as_text: true,
                    setup: (editor) => {
                        editor.on("init", (event) => {
                            document.querySelector(`label[for="${editor.targetElm.id}"]`)?.addEventListener("click", (e) => {
                                e.preventDefault();
                                e.stopImmediatePropagation();
                                editor.focus();
                            });
                            //editor.getBody().setAttribute("aria-label", document.querySelector(`label[for="${editor.targetElm.id}"]`)?.textContent() || "");                        
                            editor.setContent(editor.targetElm.value);
                            if (event.target.targetElm.hasAttribute("required") && event.target.getContent() == "")
                                event.target.editorContainer.classList.add("invalid");
                            if (event.target.targetElm.hasAttribute("disabled") || event.target.targetElm.hasAttribute("readonly")) {
                                editor.mode.set("readonly");
                            }

                            /*new MutationObserver(function(mutations) {
                                mutations.forEach(function(mutation) {
                                  if (mutation.type === "attributes" && mutation.attributeName === "disabled") {
                                    //editor.getBody().setAttribute('contenteditable', !mutation.target.hasAttribute("disabled"));
                                    editor.readonly = mutation.target.hasAttribute("disabled");
                                    editor.editorReadOnly = mutation.target.hasAttribute("disabled");
                                    editor.setDirty(true);
                                    //editor.getBody().setAttribute('contenteditable', !mutation.target.hasAttribute("disabled"));
                                  }
                                });
                            }).observe(event.target.targetElm, { attributes: true });*/
                        });
                        editor.on("SetContent", (event) => {
                            setTimeout(() => {
                                if (editor.targetElm.disabled || editor.targetElm.readonly ) {
                                    editor.mode.set("readonly");
                                }
                            }, 1000)
                        })
                        editor.on('keydown', (event) => {
                            if ((event.key === "Backspace" || event.code === "Backspace") && (editor.selection.getNode().getAttribute("contenteditable") == false || editor.selection.getNode().previousSibling?.getAttribute("contenteditable") == "false" || editor.selection.getNode().previousSibling?.querySelector(".mceNonEditable")?.getAttribute("contenteditable") == "false")) {
                                event.preventDefault();
                                return false;
                            }
                            if ((event.key === "Delete" || event.code === "Delete") && (editor.selection.getNode().getAttribute("contenteditable") == false || editor.selection.getNode().nextSibling?.getAttribute("contenteditable") == "false" || editor.selection.getNode().nextSibling?.querySelector(".mceNonEditable")?.getAttribute("contenteditable") == "false")) {
                                event.preventDefault();
                                return false;
                            }
                            //if (editor.selection.getNode().getAttribute("contenteditable") == "false" || editor.selection.getNode().closest(`[contenteditable="false"]`) != null) {
                            if (editor.selection.getNode().getAttribute("contenteditable") == "false" || editor.selection.getNode().closest(`[contenteditable]`)?.getAttribute("contenteditable") == "false") {
                                event.preventDefault();
                                return false;
                            }
                        });
                        editor.on('click', (event) => {
                            if (editor.selection.getNode().getAttribute("contenteditable") == "false" || editor.selection.getNode().closest(`[contenteditable="false"]`) != null) {
                                event.preventDefault();
                                return false;
                            }
                        });
                        editor.on('contextmenu', (event) => {
                            if (editor.selection.getNode().getAttribute("contenteditable") == "false" || editor.selection.getNode().closest(`[contenteditable="false"]`) != null) {
                                event.preventDefault();
                                return false;
                            }
                        });
                        editor.on("input", () => {
                            editor.save();
                            editor.targetElm.value = editor.getContent();
                            editor.targetElm.dispatchEvent(new Event("input"));
                            if (editor.targetElm.hasAttribute("required") && editor.getContent() == "")
                                editor.editorContainer.classList.add("invalid");
                            else
                                editor.editorContainer.classList.remove("invalid");
                        });
                        editor.on("change", (event) => {
                            editor.save();
                            editor.targetElm.value = editor.getContent();
                            editor.targetElm.dispatchEvent(new Event("input"));
                            if (event.target.targetElm.hasAttribute("required") && event.target.getContent() == "")
                                event.target.editorContainer.classList.add("invalid");
                            else
                                event.target.editorContainer.classList.remove("invalid");
                        });
                        editor.on("undo", (event) => {
                            editor.save();
                            editor.targetElm.value = editor.getContent();
                            editor.targetElm.dispatchEvent(new Event("input"));
                            if (event.target.targetElm.hasAttribute("required") && event.target.getContent() == "")
                                event.target.editorContainer.classList.add("invalid");
                            else
                                event.target.editorContainer.classList.remove("invalid");
                        });
                        editor.on("redo", (event) => {
                            editor.save();
                            editor.targetElm.value = editor.getContent();
                            editor.targetElm.dispatchEvent(new Event("input"));
                            if (event.target.targetElm.hasAttribute("required") && event.target.getContent() == "")
                                event.target.editorContainer.classList.add("invalid");
                            else
                                event.target.editorContainer.classList.remove("invalid");
                        });
                    }
                });

                element.addEventListener("change", (event) => {
                    try {
                        const editor = tinymce.get(element.id);
                        if (!editor) return;
                        if (editor.getContent() != element.value) editor.setContent(element.value);
                        if (editor.targetElm.hasAttribute("required") && editor.getContent() == "")
                            editor.editorContainer.classList.add("invalid");
                        else
                            editor.editorContainer.classList.remove("invalid");
                    } catch (ex) {
                        console.error(ex);
                        showErrorToast(ex.message || ex);
                    }
                });
                var varListTypes = (element.getAttribute("tinymce-variables-type") || "").split("|").filter(o => o != "");
                var varListExtras = (element.getAttribute("tinymce-variables-extra") || "").split("|").filter(o => o != "");
                element.setAttribute("tinymce-variables", getVariablesList(varListTypes, varListExtras));
            } catch(ex) {
                console.error(ex);
                showErrorToast(ex.message || ex);
            }
        });

        if (baseElement.parentElement instanceof Node) {
            new MutationObserver((mutations) => {
                mutations.forEach((mutation) => {
                    if (mutation.type !== 'childList') return;
                    if (mutation.removedNodes.length == 0) return;
                    if (mutation.removedNodes[0] !== baseElement) return;

                    baseElement.querySelectorAll(`textarea, div.wysiwyg`).forEach(element => {
                        try {
                            tinymce.remove(tinymce.get(element.id));
                        } catch(ex) {
                            console.error(ex);
                        }
                    });
                });
            }).observe(baseElement.parentElement, { childList: true });
        }
    } catch(ex) {
        console.error(ex);
        showErrorToast(ex.message || ex);
    }
}

var getVariablesList = (types, extras) => {
    var ret = [];
    if(types.includes("event")){
        ret = ret.concat(eventVars);
    }
    if(types.includes("registrant")){
        ret = ret.concat(registrantVars);
    }
    if(types.includes("tenant")){
        ret = ret.concat(tenantVars);
    }
    if(types.includes("account")){
        ret = ret.concat(accountVars);
    }
    ret = ret.concat(extras);
    return ret.join("|");
}

const eventVars = [
    "Event Name:event.name",
    "Event Subtitle:event.properties.subtitle",
    "Event Description:event.properties.description",
    "Event Identifier:event.identifier",
    "Event Start:event.begin",
    "Event Timezone:event.timezone",
    "Event Duration:event.duration",
    "Event Venue Location:event.properties.venueLocation",
    "Event Venue Name:event.properties.venueName",
    "Event Venue Description:event.properties.venueDescription",
];

const registrantVars = [
    //Fix the missing urls
    "Registrant First Name:registrant.firstName",
    "Registrant Last Name:registrant.lastName",
    "Registrant Email:registrant.email",
    "Registrant Event URL:null",
    "Registrant Survey URL:null",
    "Registrant Certificate URL:null"
];

const tenantVars = [
    //Is that url even needed???
    "Tenant Name:tenant.name",
    "Tenant Hostname:tenant.hostname",
    "Tenant Change Password URL:null"
];

const accountVars = [
    //Account role needed?
    "Account Id:account.id",
    "Account First Name:account.firstName",
    "Account Last Name:account.lastName",
    "Account Email:account.email",
    "Account Role:null"
]