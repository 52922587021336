/* Sample Elements

            <select displaySize="md" displayTemplate="<p>{value}</p><p>{text}</p><img src='{custom-value}'>" is="eb-select" id="eventDetailPageStyle" aria-describedby="eventDetailPageStyleHelp" required data-lzproperty="this.data.pageStyle.value" data-lzdisabled="this.data.pageStyle.lockedByAncestor">
                <option custom-value="https://i.kym-cdn.com/photos/images/newsfeed/001/297/938/8e6.png" value="classic">Classic</option>
                <option custom-value="https://i.kym-cdn.com/photos/images/newsfeed/001/297/938/8e6.png" value="marquee">Marquee</option>
                <option custom-value="https://i.kym-cdn.com/photos/images/newsfeed/001/297/938/8e6.png" value="clean">Clean</option>
                <option custom-value="https://i.kym-cdn.com/photos/images/newsfeed/001/297/938/8e6.png" value="custom" data-lzdisabled="this.features.customPageStyle != true">Custom</option>
            </select>



*/

export class EBSelect extends HTMLSelectElement{
    _options = [];
    valueKey = "value";
    textKey = "text";
    displayTemplate = ``;

    dropDownMenu;

    displaySize = 'sm';

    

    constructor(opts = {}) {
        super();
        this.className = "eb-select";

        this.valueKey = opts?.valueKey || this.getAttribute("valueKey") || "value";
        this.textKey = opts?.textKey || this.getAttribute("textKey") || "text";
        this.displayTemplate = opts?.displayTemplate || this.getAttribute("displayTemplate") || `{text}`;
        this.displaySize = opts?.displaySize || this.getAttribute("displaySize") || 'sm';

        this.parentElement.style.position = "relative";

        if(this.children.length > 0){
            for(var i = 0; i < this.children.length; i++){
                var _option = {};
                var child = this.children[i];
                _option.text = child.innerText;
                for(var j = 0; j < child.attributes.length; j++){
                    var a = child.attributes[j];
                    //var a = child.attributes[j].split("=");
                    var key = a.nodeName;
                    var value = a.nodeValue;
                    _option[key] = value;
                }
                this._options.push(_option);
            }
        }

        if(!!opts?.options){
            opts.options.forEach(o => {
                this._options.push(o);
                const option = document.createElement("option");
                option.value = o[this.valueKey];
                option.innerText = o[this.textKey];
                this.options.add(option);
            })
        }

        this.addEventListener("click", (e) => {
            e.preventDefault();
        });
        this.addEventListener("mousedown", (e) => {
            e.preventDefault();
            if(!!this.dropDownMenu) return removeSelectMenu();
            createSelectMenu();
        });
        this.addEventListener("keydown", (e) => {
            if(e.code === "Space"){
                e.preventDefault();
                if(!!this.dropDownMenu) return removeSelectMenu();
                createSelectMenu();
            }
            if(!!this.dropDownMenu) {
                e.preventDefault();
            }
            //e.preventDefault();
        });

        const createSelectMenu = () => {
            const _container = document.createElement("div");
            _container.className = "eb-select-dropdown-container";
            const container = _container.appendChild(document.createElement("div"));
            container.className = `eb-select-dropdown-menu ${this.displaySize}`;
            container.style.width = this.offsetWidth + "px";
            const selected = this.getSelected();
            Array.from(this.options).forEach(option => {
                const _option = this._options.filter(o => o[this.valueKey] == option.value)[0];
                const div = container.appendChild(document.createElement("div"));
                div.className = "eb-select-dropdown-child";
                if(selected.includes(option.value)){
                    div.classList.add("selected");
                }
                if(option.disabled){
                    div.classList.add("disabled");
                    div.setAttribute("eb-disabled", true);
                }
                div.innerHTML = replaceVariables(_option);
                div.addEventListener("click", (e) => {
                    if(div.getAttribute("eb-disabled")) return;
                    if(this.multiple){
                        option.selected = !option.selected;
                        if(option.selected) div.classList.add("selected");
                        else div.classList.remove("selected");
                    } else {
                        this.value = option.value;
                        //option.selected = true;
                        removeSelectMenu();
                    }
                    var event = new Event("change");
                    this.dispatchEvent(event);
                })
            });
            this.dropDownMenu = _container;
            this.after(_container);

            /*
            document.addEventListener("mousedown", (ev) => {
                console.log(ev);
                console.log(ev.target);
                console.log(Array.from(container.getElementsByTagName("*")));
                if(this === ev.target) return;
                if(Array.from(this.getElementsByTagName("*")).includes(ev.target)) return;
                if(Array.from(container.getElementsByTagName("*")).includes(ev.target)) return;
                removeSelectMenu();
            })
                */
        }

        const removeSelectMenu = () => {
            if(!!!this.dropDownMenu) return;
            this.dropDownMenu.remove();
            this.dropDownMenu = null;
        }

        const replaceVariables = (option) => {
            var display = this.displayTemplate;
            Object.keys(option).forEach(key => {
                display = display.split(`{${key}}`).join(option[key].toString());
            });
            return display;
        }

        this.getSelected = () => {
            var selected = [];
            for(var i = 0; i < this.options.length; i++){
                if(this.options[i].selected === true){
                    selected.push(this.options[i].value);
                }
            }
            return selected;
        }
    }
}

customElements.define("eb-select", EBSelect, {extends: "select"});