import { Modal } from ".";
import EBModalLayout from "../layouts/modal";
import { EBSession } from "../utils/session";
import { showErrorToast, showSuccessToast } from "../utils/toast";

export function TenantSelectModal() {
    const instance = new EBModalLayout();
    instance.title = "Select Tenant";
    instance.content = `<form>
            <div class="form-control form-control--vertical">
                <div>
                    <label for="tenantSelectModalTenant">Tenant</label>
                    <span id="tenantSelectModalTenantHelp"></span>
                </div>
                <div>
                    <div class="input-group">
                        <select id="tenantSelectModalTenant" name="tenant" aria-describedby="tenantSelectModalTenantHelp">${EBSession.account.tenants.map(t => `<option value="${t.id}" ${EBSession.tenant.id == t.id ? "selected" : ""}>${t.name}</option>`).join("")}</select>
                    </div>
                </div>
            </div>
        </form>`;

    instance.addControl("Change Tenant", { icon: "save", className: "primary" }).addEventListener("click", (ev) => {
        const tenantId = instance.contentElement.querySelector("#tenantSelectModalTenant").value;
        instance.close(tenantId);
    });

    instance.addControl("Cancel").addEventListener("click", (ev) => {
        instance.close();
    });

    return instance.open();
}