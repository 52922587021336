const dayjs = require("dayjs");
const utc = require("dayjs/plugin/utc.js");
const timezone = require("dayjs/plugin/timezone.js");
const advancedFormat = require("dayjs/plugin/advancedFormat.js");
const duration = require("dayjs/plugin/duration.js");
const customParseFormat = require('dayjs/plugin/customParseFormat');
const relativeTime = require('dayjs/plugin/relativeTime');
const { DurationFormat } = require('@formatjs/intl-durationformat');
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);
dayjs.extend(duration);
dayjs.extend(customParseFormat);
dayjs.extend(relativeTime);

const timezones = [
  {
    "displayName": "International Date Line West",
    "windowsZone": "Dateline Standard Time",
    "ianaZone": "Etc/GMT+12",
    "zones": [
      "Dateline Standard Time",
      "Etc/GMT+12"
    ]
  },
  {
    "displayName": "Coordinated Universal Time-11",
    "windowsZone": "UTC-11",
    "ianaZone": "Etc/GMT+11",
    "zones": [
      "UTC-11",
      "Etc/GMT+11",
      "Pacific/Pago_Pago",
      "Pacific/Niue",
      "Pacific/Midway"
    ]
  },
  {
    "displayName": "Aleutian Islands",
    "windowsZone": "Aleutian Standard Time",
    "ianaZone": "America/Adak",
    "zones": [
      "Aleutian Standard Time",
      "America/Adak"
    ]
  },
  {
    "displayName": "Hawaii",
    "windowsZone": "Hawaiian Standard Time",
    "ianaZone": "Pacific/Honolulu",
    "zones": [
      "Hawaiian Standard Time",
      "Pacific/Honolulu",
      "Pacific/Rarotonga",
      "Pacific/Tahiti",
      "Pacific/Johnston",
      "Etc/GMT+10"
    ]
  },
  {
    "displayName": "Marquesas Islands",
    "windowsZone": "Marquesas Standard Time",
    "ianaZone": "Pacific/Marquesas",
    "zones": [
      "Marquesas Standard Time",
      "Pacific/Marquesas"
    ]
  },
  {
    "displayName": "Alaska",
    "windowsZone": "Alaskan Standard Time",
    "ianaZone": "America/Anchorage",
    "zones": [
      "Alaskan Standard Time",
      "America/Anchorage",
      "America/Juneau",
      "America/Metlakatla",
      "America/Nome",
      "America/Sitka",
      "America/Yakutat"
    ]
  },
  {
    "displayName": "Coordinated Universal Time-09",
    "windowsZone": "UTC-09",
    "ianaZone": "Etc/GMT+9",
    "zones": [
      "UTC-09",
      "Etc/GMT+9",
      "Pacific/Gambier"
    ]
  },
  {
    "displayName": "Baja California",
    "windowsZone": "Pacific Standard Time (Mexico)",
    "ianaZone": "America/Tijuana",
    "zones": [
      "Pacific Standard Time (Mexico)",
      "America/Tijuana",
      "America/Santa_Isabel"
    ]
  },
  {
    "displayName": "Coordinated Universal Time-08",
    "windowsZone": "UTC-08",
    "ianaZone": "Etc/GMT+8",
    "zones": [
      "UTC-08",
      "Etc/GMT+8",
      "Pacific/Pitcairn"
    ]
  },
  {
    "displayName": "Pacific Time (US & Canada)",
    "windowsZone": "Pacific Standard Time",
    "ianaZone": "America/Los_Angeles",
    "zones": [
      "Pacific Standard Time",
      "America/Los_Angeles",
      "America/Vancouver",
      "America/Dawson",
      "America/Whitehorse",
      "PST8PDT"
    ]
  },
  {
    "displayName": "Arizona",
    "windowsZone": "US Mountain Standard Time",
    "ianaZone": "America/Phoenix",
    "zones": [
      "US Mountain Standard Time",
      "America/Phoenix",
      "America/Dawson_Creek",
      "America/Creston",
      "America/Fort_Nelson",
      "America/Hermosillo",
      "Etc/GMT+7"
    ]
  },
  {
    "displayName": "La Paz, Mazatlan",
    "windowsZone": "Mountain Standard Time (Mexico)",
    "ianaZone": "America/Chihuahua",
    "zones": [
      "Mountain Standard Time (Mexico)",
      "America/Chihuahua",
      "America/Mazatlan"
    ]
  },
  {
    "displayName": "Mountain Time (US & Canada)",
    "windowsZone": "Mountain Standard Time",
    "ianaZone": "America/Denver",
    "zones": [
      "Mountain Standard Time",
      "America/Denver",
      "America/Edmonton",
      "America/Cambridge_Bay",
      "America/Inuvik",
      "America/Yellowknife",
      "America/Ojinaga",
      "America/Boise",
      "MST7MDT"
    ]
  },
  /*{
    "displayName": "Yukon",
    "windowsZone": "Yukon Standard Time",
    "ianaZone": "",
    "zones": [
      "Yukon Standard Time"
    ]
  },*/
  {
    "displayName": "Central America",
    "windowsZone": "Central America Standard Time",
    "ianaZone": "America/Guatemala",
    "zones": [
      "Central America Standard Time",
      "America/Guatemala",
      "America/Belize",
      "America/Costa_Rica",
      "Pacific/Galapagos",
      "America/Tegucigalpa",
      "America/Managua",
      "America/El_Salvador",
      "Etc/GMT+6"
    ]
  },
  {
    "displayName": "Central Time (US & Canada)",
    "windowsZone": "Central Standard Time",
    "ianaZone": "America/Chicago",
    "zones": [
      "Central Standard Time",
      "America/Chicago",
      "America/Winnipeg",
      "America/Rainy_River",
      "America/Rankin_Inlet",
      "America/Resolute",
      "America/Matamoros",
      "America/Indiana/Knox",
      "America/Indiana/Tell_City",
      "America/Menominee",
      "America/North_Dakota/Beulah",
      "America/North_Dakota/Center",
      "America/North_Dakota/New_Salem",
      "CST6CDT"
    ]
  },
  {
    "displayName": "Easter Island",
    "windowsZone": "Easter Island Standard Time",
    "ianaZone": "Pacific/Easter",
    "zones": [
      "Easter Island Standard Time",
      "Pacific/Easter"
    ]
  },
  {
    "displayName": "Guadalajara, Mexico City, Monterrey",
    "windowsZone": "Central Standard Time (Mexico)",
    "ianaZone": "America/Mexico_City",
    "zones": [
      "Central Standard Time (Mexico)",
      "America/Mexico_City",
      "America/Bahia_Banderas",
      "America/Merida",
      "America/Monterrey"
    ]
  },
  {
    "displayName": "Saskatchewan",
    "windowsZone": "Canada Central Standard Time",
    "ianaZone": "America/Regina",
    "zones": [
      "Canada Central Standard Time",
      "America/Regina",
      "America/Swift_Current"
    ]
  },
  {
    "displayName": "Bogota, Lima, Quito, Rio Branco",
    "windowsZone": "SA Pacific Standard Time",
    "ianaZone": "America/Bogota",
    "zones": [
      "SA Pacific Standard Time",
      "America/Bogota",
      "America/Rio_Branco",
      "America/Eirunepe",
      "America/Coral_Harbour",
      "America/Guayaquil",
      "America/Jamaica",
      "America/Cayman",
      "America/Panama",
      "America/Lima",
      "Etc/GMT+5"
    ]
  },
  {
    "displayName": "Chetumal",
    "windowsZone": "Eastern Standard Time (Mexico)",
    "ianaZone": "America/Cancun",
    "zones": [
      "Eastern Standard Time (Mexico)",
      "America/Cancun"
    ]
  },
  {
    "displayName": "Eastern Time (US & Canada)",
    "windowsZone": "Eastern Standard Time",
    "ianaZone": "America/New_York",
    "zones": [
      "Eastern Standard Time",
      "America/New_York",
      "America/Nassau",
      "America/Toronto",
      "America/Iqaluit",
      "America/Montreal",
      "America/Nipigon",
      "America/Pangnirtung",
      "America/Thunder_Bay",
      "America/Detroit",
      "America/Indiana/Petersburg",
      "America/Indiana/Vincennes",
      "America/Indiana/Winamac",
      "America/Kentucky/Monticello",
      "America/Louisville",
      "EST5EDT"
    ]
  },
  {
    "displayName": "Haiti",
    "windowsZone": "Haiti Standard Time",
    "ianaZone": "America/Port-au-Prince",
    "zones": [
      "Haiti Standard Time",
      "America/Port-au-Prince"
    ]
  },
  {
    "displayName": "Havana",
    "windowsZone": "Cuba Standard Time",
    "ianaZone": "America/Havana",
    "zones": [
      "Cuba Standard Time",
      "America/Havana"
    ]
  },
  {
    "displayName": "Indiana (East)",
    "windowsZone": "US Eastern Standard Time",
    "ianaZone": "America/Indianapolis",
    "zones": [
      "US Eastern Standard Time",
      "America/Indianapolis",
      "America/Indiana/Marengo",
      "America/Indiana/Vevay"
    ]
  },
  {
    "displayName": "Turks and Caicos",
    "windowsZone": "Turks And Caicos Standard Time",
    "ianaZone": "America/Grand_Turk",
    "zones": [
      "Turks And Caicos Standard Time",
      "America/Grand_Turk"
    ]
  },
  {
    "displayName": "Asuncion",
    "windowsZone": "Paraguay Standard Time",
    "ianaZone": "America/Asuncion",
    "zones": [
      "Paraguay Standard Time",
      "America/Asuncion"
    ]
  },
  {
    "displayName": "Atlantic Time (Canada)",
    "windowsZone": "Atlantic Standard Time",
    "ianaZone": "America/Halifax",
    "zones": [
      "Atlantic Standard Time",
      "America/Halifax",
      "Atlantic/Bermuda",
      "America/Glace_Bay",
      "America/Goose_Bay",
      "America/Moncton",
      "America/Thule"
    ]
  },
  {
    "displayName": "Caracas",
    "windowsZone": "Venezuela Standard Time",
    "ianaZone": "America/Caracas",
    "zones": [
      "Venezuela Standard Time",
      "America/Caracas"
    ]
  },
  {
    "displayName": "Cuiaba",
    "windowsZone": "Central Brazilian Standard Time",
    "ianaZone": "America/Cuiaba",
    "zones": [
      "Central Brazilian Standard Time",
      "America/Cuiaba",
      "America/Campo_Grande"
    ]
  },
  {
    "displayName": "Georgetown, La Paz, Manaus, San Juan",
    "windowsZone": "SA Western Standard Time",
    "ianaZone": "America/La_Paz",
    "zones": [
      "SA Western Standard Time",
      "America/La_Paz",
      "America/Antigua",
      "America/Anguilla",
      "America/Aruba",
      "America/Barbados",
      "America/St_Barthelemy",
      "America/Kralendijk",
      "America/Manaus",
      "America/Boa_Vista",
      "America/Porto_Velho",
      "America/Blanc-Sablon",
      "America/Curacao",
      "America/Dominica",
      "America/Santo_Domingo",
      "America/Grenada",
      "America/Guadeloupe",
      "America/Guyana",
      "America/St_Kitts",
      "America/St_Lucia",
      "America/Marigot",
      "America/Martinique",
      "America/Montserrat",
      "America/Puerto_Rico",
      "America/Lower_Princes",
      "America/Port_of_Spain",
      "America/St_Vincent",
      "America/Tortola",
      "America/St_Thomas",
      "Etc/GMT+4"
    ]
  },
  {
    "displayName": "Santiago",
    "windowsZone": "Pacific SA Standard Time",
    "ianaZone": "America/Santiago",
    "zones": [
      "Pacific SA Standard Time",
      "America/Santiago"
    ]
  },
  {
    "displayName": "Newfoundland",
    "windowsZone": "Newfoundland Standard Time",
    "ianaZone": "America/St_Johns",
    "zones": [
      "Newfoundland Standard Time",
      "America/St_Johns"
    ]
  },
  {
    "displayName": "Araguaina",
    "windowsZone": "Tocantins Standard Time",
    "ianaZone": "America/Araguaina",
    "zones": [
      "Tocantins Standard Time",
      "America/Araguaina"
    ]
  },
  {
    "displayName": "Brasilia",
    "windowsZone": "E. South America Standard Time",
    "ianaZone": "America/Sao_Paulo",
    "zones": [
      "E. South America Standard Time",
      "America/Sao_Paulo"
    ]
  },
  {
    "displayName": "Cayenne, Fortaleza",
    "windowsZone": "SA Eastern Standard Time",
    "ianaZone": "America/Cayenne",
    "zones": [
      "SA Eastern Standard Time",
      "America/Cayenne",
      "Antarctica/Rothera",
      "Antarctica/Palmer",
      "America/Fortaleza",
      "America/Belem",
      "America/Maceio",
      "America/Recife",
      "America/Santarem",
      "Atlantic/Stanley",
      "America/Paramaribo",
      "Etc/GMT+3"
    ]
  },
  {
    "displayName": "City of Buenos Aires",
    "windowsZone": "Argentina Standard Time",
    "ianaZone": "America/Buenos_Aires",
    "zones": [
      "Argentina Standard Time",
      "America/Buenos_Aires",
      "America/Argentina/La_Rioja",
      "America/Argentina/Rio_Gallegos",
      "America/Argentina/Salta",
      "America/Argentina/San_Juan",
      "America/Argentina/San_Luis",
      "America/Argentina/Tucuman",
      "America/Argentina/Ushuaia",
      "America/Catamarca",
      "America/Cordoba",
      "America/Jujuy",
      "America/Mendoza"
    ]
  },
  {
    "displayName": "Greenland",
    "windowsZone": "Greenland Standard Time",
    "ianaZone": "America/Godthab",
    "zones": [
      "Greenland Standard Time",
      "America/Godthab",
      "America/Nuuk"
    ]
  },
  {
    "displayName": "Montevideo",
    "windowsZone": "Montevideo Standard Time",
    "ianaZone": "America/Montevideo",
    "zones": [
      "Montevideo Standard Time",
      "America/Montevideo"
    ]
  },
  {
    "displayName": "Punta Arenas",
    "windowsZone": "Magallanes Standard Time",
    "ianaZone": "America/Punta_Arenas",
    "zones": [
      "Magallanes Standard Time",
      "America/Punta_Arenas"
    ]
  },
  {
    "displayName": "Saint Pierre and Miquelon",
    "windowsZone": "Saint Pierre Standard Time",
    "ianaZone": "America/Miquelon",
    "zones": [
      "Saint Pierre Standard Time",
      "America/Miquelon"
    ]
  },
  {
    "displayName": "Salvador",
    "windowsZone": "Bahia Standard Time",
    "ianaZone": "America/Bahia",
    "zones": [
      "Bahia Standard Time",
      "America/Bahia"
    ]
  },
  {
    "displayName": "Coordinated Universal Time-02",
    "windowsZone": "UTC-02",
    "ianaZone": "Etc/GMT+2",
    "zones": [
      "UTC-02",
      "Etc/GMT+2",
      "America/Noronha",
      "Atlantic/South_Georgia"
    ]
  },
  {
    "displayName": "Azores",
    "windowsZone": "Azores Standard Time",
    "ianaZone": "Atlantic/Azores",
    "zones": [
      "Azores Standard Time",
      "Atlantic/Azores",
      "America/Scoresbysund"
    ]
  },
  {
    "displayName": "Cabo Verde Is.",
    "windowsZone": "Cape Verde Standard Time",
    "ianaZone": "Atlantic/Cape_Verde",
    "zones": [
      "Cape Verde Standard Time",
      "Atlantic/Cape_Verde",
      "Etc/GMT+1"
    ]
  },
  {
    "displayName": "Coordinated Universal Time",
    "windowsZone": "UTC",
    "ianaZone": "Etc/GMT",
    "zones": [
      "UTC",
      "Etc/GMT",
      "America/Danmarkshavn",
      "Etc/UTC"
    ]
  },
  {
    "displayName": "Dublin, Edinburgh, Lisbon, London ",
    "windowsZone": "GMT Standard Time",
    "ianaZone": "Europe/London",
    "zones": [
      "GMT Standard Time",
      "Europe/London",
      "Atlantic/Canary",
      "Atlantic/Faeroe",
      "Europe/Guernsey",
      "Europe/Dublin",
      "Europe/Isle_of_Man",
      "Europe/Jersey",
      "Europe/Lisbon",
      "Atlantic/Madeira"
    ]
  },
  {
    "displayName": "Monrovia, Reykjavik",
    "windowsZone": "Greenwich Standard Time",
    "ianaZone": "Atlantic/Reykjavik",
    "zones": [
      "Greenwich Standard Time",
      "Atlantic/Reykjavik",
      "Africa/Ouagadougou",
      "Africa/Abidjan",
      "Africa/Accra",
      "Africa/Banjul",
      "Africa/Conakry",
      "Africa/Bissau",
      "Africa/Monrovia",
      "Africa/Bamako",
      "Africa/Nouakchott",
      "Atlantic/St_Helena",
      "Africa/Freetown",
      "Africa/Dakar",
      "Africa/Lome"
    ]
  },
  {
    "displayName": "Sao Tome",
    "windowsZone": "Sao Tome Standard Time",
    "ianaZone": "Africa/Sao_Tome",
    "zones": [
      "Sao Tome Standard Time",
      "Africa/Sao_Tome"
    ]
  },
  {
    "displayName": "Casablanca",
    "windowsZone": "Morocco Standard Time",
    "ianaZone": "Africa/Casablanca",
    "zones": [
      "Morocco Standard Time",
      "Africa/Casablanca",
      "Africa/El_Aaiun"
    ]
  },
  {
    "displayName": "Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
    "windowsZone": "W. Europe Standard Time",
    "ianaZone": "Europe/Berlin",
    "zones": [
      "W. Europe Standard Time",
      "Europe/Berlin",
      "Europe/Andorra",
      "Europe/Vienna",
      "Europe/Zurich",
      "Europe/Busingen",
      "Europe/Gibraltar",
      "Europe/Rome",
      "Europe/Vaduz",
      "Europe/Luxembourg",
      "Europe/Monaco",
      "Europe/Malta",
      "Europe/Amsterdam",
      "Europe/Oslo",
      "Europe/Stockholm",
      "Arctic/Longyearbyen",
      "Europe/San_Marino",
      "Europe/Vatican"
    ]
  },
  {
    "displayName": "Belgrade, Bratislava, Budapest, Ljubljana, Prague",
    "windowsZone": "Central Europe Standard Time",
    "ianaZone": "Europe/Budapest",
    "zones": [
      "Central Europe Standard Time",
      "Europe/Budapest",
      "Europe/Tirane",
      "Europe/Prague",
      "Europe/Podgorica",
      "Europe/Belgrade",
      "Europe/Ljubljana",
      "Europe/Bratislava"
    ]
  },
  {
    "displayName": "Brussels, Copenhagen, Madrid, Paris",
    "windowsZone": "Romance Standard Time",
    "ianaZone": "Europe/Paris",
    "zones": [
      "Romance Standard Time",
      "Europe/Paris",
      "Europe/Brussels",
      "Europe/Copenhagen",
      "Europe/Madrid",
      "Africa/Ceuta"
    ]
  },
  {
    "displayName": "Sarajevo, Skopje, Warsaw, Zagreb",
    "windowsZone": "Central European Standard Time",
    "ianaZone": "Europe/Warsaw",
    "zones": [
      "Central European Standard Time",
      "Europe/Warsaw",
      "Europe/Sarajevo",
      "Europe/Zagreb",
      "Europe/Skopje"
    ]
  },
  {
    "displayName": "West Central Africa",
    "windowsZone": "W. Central Africa Standard Time",
    "ianaZone": "Africa/Lagos",
    "zones": [
      "W. Central Africa Standard Time",
      "Africa/Lagos",
      "Africa/Luanda",
      "Africa/Porto-Novo",
      "Africa/Kinshasa",
      "Africa/Bangui",
      "Africa/Brazzaville",
      "Africa/Douala",
      "Africa/Algiers",
      "Africa/Libreville",
      "Africa/Malabo",
      "Africa/Niamey",
      "Africa/Ndjamena",
      "Africa/Tunis",
      "Etc/GMT-1"
    ]
  },
  {
    "displayName": "Athens, Bucharest",
    "windowsZone": "GTB Standard Time",
    "ianaZone": "Europe/Bucharest",
    "zones": [
      "GTB Standard Time",
      "Europe/Bucharest",
      "Asia/Nicosia",
      "Asia/Famagusta",
      "Europe/Athens"
    ]
  },
  {
    "displayName": "Beirut",
    "windowsZone": "Middle East Standard Time",
    "ianaZone": "Asia/Beirut",
    "zones": [
      "Middle East Standard Time",
      "Asia/Beirut"
    ]
  },
  {
    "displayName": "Cairo",
    "windowsZone": "Egypt Standard Time",
    "ianaZone": "Africa/Cairo",
    "zones": [
      "Egypt Standard Time",
      "Africa/Cairo"
    ]
  },
  {
    "displayName": "Chisinau",
    "windowsZone": "E. Europe Standard Time",
    "ianaZone": "Europe/Chisinau",
    "zones": [
      "E. Europe Standard Time",
      "Europe/Chisinau"
    ]
  },
  {
    "displayName": "Damascus",
    "windowsZone": "Syria Standard Time",
    "ianaZone": "Asia/Damascus",
    "zones": [
      "Syria Standard Time",
      "Asia/Damascus"
    ]
  },
  {
    "displayName": "Gaza, Hebron",
    "windowsZone": "West Bank Standard Time",
    "ianaZone": "Asia/Hebron",
    "zones": [
      "West Bank Standard Time",
      "Asia/Hebron",
      "Asia/Gaza"
    ]
  },
  {
    "displayName": "Harare, Pretoria",
    "windowsZone": "South Africa Standard Time",
    "ianaZone": "Africa/Johannesburg",
    "zones": [
      "South Africa Standard Time",
      "Africa/Johannesburg",
      "Africa/Bujumbura",
      "Africa/Gaborone",
      "Africa/Lubumbashi",
      "Africa/Maseru",
      "Africa/Blantyre",
      "Africa/Maputo",
      "Africa/Kigali",
      "Africa/Mbabane",
      "Africa/Lusaka",
      "Africa/Harare",
      "Etc/GMT-2"
    ]
  },
  {
    "displayName": "Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
    "windowsZone": "FLE Standard Time",
    "ianaZone": "Europe/Kiev",
    "zones": [
      "FLE Standard Time",
      "Europe/Kiev",
      "Europe/Mariehamn",
      "Europe/Sofia",
      "Europe/Tallinn",
      "Europe/Helsinki",
      "Europe/Vilnius",
      "Europe/Riga",
      "Europe/Uzhgorod",
      "Europe/Zaporozhye"
    ]
  },
  {
    "displayName": "Jerusalem ",
    "windowsZone": "Israel Standard Time",
    "ianaZone": "Asia/Jerusalem",
    "zones": [
      "Israel Standard Time",
      "Asia/Jerusalem"
    ]
  },
  {
    "displayName": "Juba",
    "windowsZone": "South Sudan Standard Time",
    "ianaZone": "",
    "zones": [
      "South Sudan Standard Time",
      "Africa/Juba"
    ]
  },
  {
    "displayName": "Kaliningrad",
    "windowsZone": "Kaliningrad Standard Time",
    "ianaZone": "Europe/Kaliningrad",
    "zones": [
      "Kaliningrad Standard Time",
      "Europe/Kaliningrad"
    ]
  },
  {
    "displayName": "Khartoum",
    "windowsZone": "Sudan Standard Time",
    "ianaZone": "Africa/Khartoum",
    "zones": [
      "Sudan Standard Time",
      "Africa/Khartoum"
    ]
  },
  {
    "displayName": "Tripoli",
    "windowsZone": "Libya Standard Time",
    "ianaZone": "Africa/Tripoli",
    "zones": [
      "Libya Standard Time",
      "Africa/Tripoli"
    ]
  },
  {
    "displayName": "Windhoek",
    "windowsZone": "Namibia Standard Time",
    "ianaZone": "Africa/Windhoek",
    "zones": [
      "Namibia Standard Time",
      "Africa/Windhoek"
    ]
  },
  {
    "displayName": "Amman ",
    "windowsZone": "Jordan Standard Time",
    "ianaZone": "Asia/Amman",
    "zones": [
      "Jordan Standard Time",
      "Asia/Amman"
    ]
  },
  {
    "displayName": "Baghdad",
    "windowsZone": "Arabic Standard Time",
    "ianaZone": "Asia/Baghdad",
    "zones": [
      "Arabic Standard Time",
      "Asia/Baghdad"
    ]
  },
  {
    "displayName": "Istanbul",
    "windowsZone": "Turkey Standard Time",
    "ianaZone": "Europe/Istanbul",
    "zones": [
      "Turkey Standard Time",
      "Europe/Istanbul"
    ]
  },
  {
    "displayName": "Kuwait, Riyadh",
    "windowsZone": "Arab Standard Time",
    "ianaZone": "Asia/Riyadh",
    "zones": [
      "Arab Standard Time",
      "Asia/Riyadh",
      "Asia/Bahrain",
      "Asia/Kuwait",
      "Asia/Qatar",
      "Asia/Aden"
    ]
  },
  {
    "displayName": "Minsk",
    "windowsZone": "Belarus Standard Time",
    "ianaZone": "Europe/Minsk",
    "zones": [
      "Belarus Standard Time",
      "Europe/Minsk"
    ]
  },
  {
    "displayName": "Moscow, St. Petersburg",
    "windowsZone": "Russian Standard Time",
    "ianaZone": "Europe/Moscow",
    "zones": [
      "Russian Standard Time",
      "Europe/Moscow",
      "Europe/Kirov",
      "Europe/Simferopol"
    ]
  },
  {
    "displayName": "Nairobi",
    "windowsZone": "E. Africa Standard Time",
    "ianaZone": "Africa/Nairobi",
    "zones": [
      "E. Africa Standard Time",
      "Africa/Nairobi",
      "Antarctica/Syowa",
      "Africa/Djibouti",
      "Africa/Asmera",
      "Africa/Addis_Ababa",
      "Indian/Comoro",
      "Indian/Antananarivo",
      "Africa/Mogadishu",
      "Africa/Juba",
      "Africa/Dar_es_Salaam",
      "Africa/Kampala",
      "Indian/Mayotte",
      "Etc/GMT-3"
    ]
  },
  {
    "displayName": "Volgograd",
    "windowsZone": "Volgograd Standard Time",
    "ianaZone": "Europe/Volgograd",
    "zones": [
      "Volgograd Standard Time",
      "Europe/Volgograd"
    ]
  },
  {
    "displayName": "Tehran",
    "windowsZone": "Iran Standard Time",
    "ianaZone": "Asia/Tehran",
    "zones": [
      "Iran Standard Time",
      "Asia/Tehran"
    ]
  },
  {
    "displayName": "Abu Dhabi, Muscat",
    "windowsZone": "Arabian Standard Time",
    "ianaZone": "Asia/Dubai",
    "zones": [
      "Arabian Standard Time",
      "Asia/Dubai",
      "Asia/Muscat",
      "Etc/GMT-4"
    ]
  },
  {
    "displayName": "Astrakhan, Ulyanovsk",
    "windowsZone": "Astrakhan Standard Time",
    "ianaZone": "Europe/Astrakhan",
    "zones": [
      "Astrakhan Standard Time",
      "Europe/Astrakhan",
      "Europe/Ulyanovsk"
    ]
  },
  {
    "displayName": "Baku",
    "windowsZone": "Azerbaijan Standard Time",
    "ianaZone": "Asia/Baku",
    "zones": [
      "Azerbaijan Standard Time",
      "Asia/Baku"
    ]
  },
  {
    "displayName": "Izhevsk, Samara",
    "windowsZone": "Russia Time Zone 3",
    "ianaZone": "Europe/Samara",
    "zones": [
      "Russia Time Zone 3",
      "Europe/Samara"
    ]
  },
  {
    "displayName": "Port Louis",
    "windowsZone": "Mauritius Standard Time",
    "ianaZone": "Indian/Mauritius",
    "zones": [
      "Mauritius Standard Time",
      "Indian/Mauritius",
      "Indian/Reunion",
      "Indian/Mahe"
    ]
  },
  {
    "displayName": "Saratov",
    "windowsZone": "Saratov Standard Time",
    "ianaZone": "Europe/Saratov",
    "zones": [
      "Saratov Standard Time",
      "Europe/Saratov"
    ]
  },
  {
    "displayName": "Tbilisi",
    "windowsZone": "Georgian Standard Time",
    "ianaZone": "Asia/Tbilisi",
    "zones": [
      "Georgian Standard Time",
      "Asia/Tbilisi"
    ]
  },
  {
    "displayName": "Yerevan",
    "windowsZone": "Caucasus Standard Time",
    "ianaZone": "Asia/Yerevan",
    "zones": [
      "Caucasus Standard Time",
      "Asia/Yerevan"
    ]
  },
  {
    "displayName": "Kabul",
    "windowsZone": "Afghanistan Standard Time",
    "ianaZone": "Asia/Kabul",
    "zones": [
      "Afghanistan Standard Time",
      "Asia/Kabul"
    ]
  },
  {
    "displayName": "Ashgabat, Tashkent",
    "windowsZone": "West Asia Standard Time",
    "ianaZone": "Asia/Tashkent",
    "zones": [
      "West Asia Standard Time",
      "Asia/Tashkent",
      "Antarctica/Mawson",
      "Asia/Oral",
      "Asia/Aqtau",
      "Asia/Aqtobe",
      "Asia/Atyrau",
      "Indian/Maldives",
      "Indian/Kerguelen",
      "Asia/Dushanbe",
      "Asia/Ashgabat",
      "Asia/Samarkand",
      "Etc/GMT-5"
    ]
  },
  {
    "displayName": "Ekaterinburg",
    "windowsZone": "Ekaterinburg Standard Time",
    "ianaZone": "Asia/Yekaterinburg",
    "zones": [
      "Ekaterinburg Standard Time",
      "Asia/Yekaterinburg"
    ]
  },
  {
    "displayName": "Islamabad, Karachi",
    "windowsZone": "Pakistan Standard Time",
    "ianaZone": "Asia/Karachi",
    "zones": [
      "Pakistan Standard Time",
      "Asia/Karachi"
    ]
  },
  {
    "displayName": "Qyzylorda",
    "windowsZone": "Qyzylorda Standard Time",
    "ianaZone": "Asia/Qyzylorda",
    "zones": [
      "Qyzylorda Standard Time",
      "Asia/Qyzylorda"
    ]
  },
  {
    "displayName": "Chennai, Kolkata, Mumbai, New Delhi",
    "windowsZone": "India Standard Time",
    "ianaZone": "Asia/Calcutta",
    "zones": [
      "India Standard Time",
      "Asia/Calcutta"
    ]
  },
  {
    "displayName": "Sri Jayawardenepura",
    "windowsZone": "Sri Lanka Standard Time",
    "ianaZone": "Asia/Colombo",
    "zones": [
      "Sri Lanka Standard Time",
      "Asia/Colombo"
    ]
  },
  {
    "displayName": "Kathmandu",
    "windowsZone": "Nepal Standard Time",
    "ianaZone": "Asia/Katmandu",
    "zones": [
      "Nepal Standard Time",
      "Asia/Katmandu"
    ]
  },
  {
    "displayName": "Astana",
    "windowsZone": "Central Asia Standard Time",
    "ianaZone": "Asia/Almaty",
    "zones": [
      "Central Asia Standard Time",
      "Asia/Almaty",
      "Antarctica/Vostok",
      "Asia/Urumqi",
      "Indian/Chagos",
      "Asia/Bishkek",
      "Asia/Qostanay",
      "Etc/GMT-6"
    ]
  },
  {
    "displayName": "Dhaka",
    "windowsZone": "Bangladesh Standard Time",
    "ianaZone": "Asia/Dhaka",
    "zones": [
      "Bangladesh Standard Time",
      "Asia/Dhaka",
      "Asia/Thimphu"
    ]
  },
  {
    "displayName": "Omsk",
    "windowsZone": "Omsk Standard Time",
    "ianaZone": "Asia/Omsk",
    "zones": [
      "Omsk Standard Time",
      "Asia/Omsk"
    ]
  },
  {
    "displayName": "Yangon (Rangoon)",
    "windowsZone": "Myanmar Standard Time",
    "ianaZone": "Asia/Rangoon",
    "zones": [
      "Myanmar Standard Time",
      "Asia/Rangoon",
      "Indian/Cocos"
    ]
  },
  {
    "displayName": "Bangkok, Hanoi, Jakarta",
    "windowsZone": "SE Asia Standard Time",
    "ianaZone": "Asia/Bangkok",
    "zones": [
      "SE Asia Standard Time",
      "Asia/Bangkok",
      "Antarctica/Davis",
      "Indian/Christmas",
      "Asia/Jakarta",
      "Asia/Pontianak",
      "Asia/Phnom_Penh",
      "Asia/Vientiane",
      "Asia/Saigon",
      "Etc/GMT-7"
    ]
  },
  {
    "displayName": "Barnaul, Gorno-Altaysk",
    "windowsZone": "Altai Standard Time",
    "ianaZone": "Asia/Barnaul",
    "zones": [
      "Altai Standard Time",
      "Asia/Barnaul"
    ]
  },
  {
    "displayName": "Hovd",
    "windowsZone": "W. Mongolia Standard Time",
    "ianaZone": "Asia/Hovd",
    "zones": [
      "W. Mongolia Standard Time",
      "Asia/Hovd"
    ]
  },
  {
    "displayName": "Krasnoyarsk ",
    "windowsZone": "North Asia Standard Time",
    "ianaZone": "Asia/Krasnoyarsk",
    "zones": [
      "North Asia Standard Time",
      "Asia/Krasnoyarsk",
      "Asia/Novokuznetsk"
    ]
  },
  {
    "displayName": "Novosibirsk",
    "windowsZone": "N. Central Asia Standard Time",
    "ianaZone": "Asia/Novosibirsk",
    "zones": [
      "N. Central Asia Standard Time",
      "Asia/Novosibirsk"
    ]
  },
  {
    "displayName": "Tomsk",
    "windowsZone": "Tomsk Standard Time",
    "ianaZone": "Asia/Tomsk",
    "zones": [
      "Tomsk Standard Time",
      "Asia/Tomsk"
    ]
  },
  {
    "displayName": "Beijing, Chongqing, Hong Kong, Urumqi",
    "windowsZone": "China Standard Time",
    "ianaZone": "Asia/Shanghai",
    "zones": [
      "China Standard Time",
      "Asia/Shanghai",
      "Asia/Hong_Kong",
      "Asia/Macau"
    ]
  },
  {
    "displayName": "Irkutsk",
    "windowsZone": "North Asia East Standard Time",
    "ianaZone": "Asia/Irkutsk",
    "zones": [
      "North Asia East Standard Time",
      "Asia/Irkutsk"
    ]
  },
  {
    "displayName": "Kuala Lumpur, Singapore",
    "windowsZone": "Singapore Standard Time",
    "ianaZone": "Asia/Singapore",
    "zones": [
      "Singapore Standard Time",
      "Asia/Singapore",
      "Antarctica/Casey",
      "Asia/Brunei",
      "Asia/Makassar",
      "Asia/Kuala_Lumpur",
      "Asia/Kuching",
      "Asia/Manila",
      "Etc/GMT-8"
    ]
  },
  {
    "displayName": "Perth",
    "windowsZone": "W. Australia Standard Time",
    "ianaZone": "Australia/Perth",
    "zones": [
      "W. Australia Standard Time",
      "Australia/Perth"
    ]
  },
  {
    "displayName": "Taipei",
    "windowsZone": "Taipei Standard Time",
    "ianaZone": "Asia/Taipei",
    "zones": [
      "Taipei Standard Time",
      "Asia/Taipei"
    ]
  },
  {
    "displayName": "Ulaanbaatar",
    "windowsZone": "Ulaanbaatar Standard Time",
    "ianaZone": "Asia/Ulaanbaatar",
    "zones": [
      "Ulaanbaatar Standard Time",
      "Asia/Ulaanbaatar",
      "Asia/Choibalsan"
    ]
  },
  {
    "displayName": "Eucla",
    "windowsZone": "Aus Central W. Standard Time",
    "ianaZone": "Australia/Eucla",
    "zones": [
      "Aus Central W. Standard Time",
      "Australia/Eucla"
    ]
  },
  {
    "displayName": "Chita",
    "windowsZone": "Transbaikal Standard Time",
    "ianaZone": "Asia/Chita",
    "zones": [
      "Transbaikal Standard Time",
      "Asia/Chita"
    ]
  },
  {
    "displayName": "Osaka, Sapporo, Tokyo",
    "windowsZone": "Tokyo Standard Time",
    "ianaZone": "Asia/Tokyo",
    "zones": [
      "Tokyo Standard Time",
      "Asia/Tokyo",
      "Asia/Jayapura",
      "Pacific/Palau",
      "Asia/Dili",
      "Etc/GMT-9"
    ]
  },
  {
    "displayName": "Pyongyang",
    "windowsZone": "North Korea Standard Time",
    "ianaZone": "Asia/Pyongyang",
    "zones": [
      "North Korea Standard Time",
      "Asia/Pyongyang"
    ]
  },
  {
    "displayName": "Seoul",
    "windowsZone": "Korea Standard Time",
    "ianaZone": "Asia/Seoul",
    "zones": [
      "Korea Standard Time",
      "Asia/Seoul"
    ]
  },
  {
    "displayName": "Yakutsk",
    "windowsZone": "Yakutsk Standard Time",
    "ianaZone": "Asia/Yakutsk",
    "zones": [
      "Yakutsk Standard Time",
      "Asia/Yakutsk",
      "Asia/Khandyga"
    ]
  },
  {
    "displayName": "Adelaide",
    "windowsZone": "Cen. Australia Standard Time",
    "ianaZone": "Australia/Adelaide",
    "zones": [
      "Cen. Australia Standard Time",
      "Australia/Adelaide",
      "Australia/Broken_Hill"
    ]
  },
  {
    "displayName": "Darwin",
    "windowsZone": "AUS Central Standard Time",
    "ianaZone": "Australia/Darwin",
    "zones": [
      "AUS Central Standard Time",
      "Australia/Darwin"
    ]
  },
  {
    "displayName": "Brisbane",
    "windowsZone": "E. Australia Standard Time",
    "ianaZone": "Australia/Brisbane",
    "zones": [
      "E. Australia Standard Time",
      "Australia/Brisbane",
      "Australia/Lindeman"
    ]
  },
  {
    "displayName": "Canberra, Melbourne, Sydney",
    "windowsZone": "AUS Eastern Standard Time",
    "ianaZone": "Australia/Sydney",
    "zones": [
      "AUS Eastern Standard Time",
      "Australia/Sydney",
      "Australia/Melbourne"
    ]
  },
  {
    "displayName": "Guam, Port Moresby",
    "windowsZone": "West Pacific Standard Time",
    "ianaZone": "Pacific/Port_Moresby",
    "zones": [
      "West Pacific Standard Time",
      "Pacific/Port_Moresby",
      "Antarctica/DumontDUrville",
      "Pacific/Truk",
      "Pacific/Guam",
      "Pacific/Saipan",
      "Etc/GMT-10"
    ]
  },
  {
    "displayName": "Hobart",
    "windowsZone": "Tasmania Standard Time",
    "ianaZone": "Australia/Hobart",
    "zones": [
      "Tasmania Standard Time",
      "Australia/Hobart",
      "Australia/Currie"
    ]
  },
  {
    "displayName": "Vladivostok",
    "windowsZone": "Vladivostok Standard Time",
    "ianaZone": "Asia/Vladivostok",
    "zones": [
      "Vladivostok Standard Time",
      "Asia/Vladivostok",
      "Asia/Ust-Nera"
    ]
  },
  {
    "displayName": "Lord Howe Island",
    "windowsZone": "Lord Howe Standard Time",
    "ianaZone": "Australia/Lord_Howe",
    "zones": [
      "Lord Howe Standard Time",
      "Australia/Lord_Howe"
    ]
  },
  {
    "displayName": "Bougainville Island",
    "windowsZone": "Bougainville Standard Time",
    "ianaZone": "Pacific/Bougainville",
    "zones": [
      "Bougainville Standard Time",
      "Pacific/Bougainville"
    ]
  },
  {
    "displayName": "Chokurdakh ",
    "windowsZone": "Russia Time Zone 10",
    "ianaZone": "Asia/Srednekolymsk",
    "zones": [
      "Russia Time Zone 10",
      "Asia/Srednekolymsk"
    ]
  },
  {
    "displayName": "Magadan",
    "windowsZone": "Magadan Standard Time",
    "ianaZone": "Asia/Magadan",
    "zones": [
      "Magadan Standard Time",
      "Asia/Magadan"
    ]
  },
  {
    "displayName": "Norfolk Island",
    "windowsZone": "Norfolk Standard Time",
    "ianaZone": "Pacific/Norfolk",
    "zones": [
      "Norfolk Standard Time",
      "Pacific/Norfolk"
    ]
  },
  {
    "displayName": "Sakhalin",
    "windowsZone": "Sakhalin Standard Time",
    "ianaZone": "Asia/Sakhalin",
    "zones": [
      "Sakhalin Standard Time",
      "Asia/Sakhalin"
    ]
  },
  {
    "displayName": "Solomon Is., New Caledonia",
    "windowsZone": "Central Pacific Standard Time",
    "ianaZone": "Pacific/Guadalcanal",
    "zones": [
      "Central Pacific Standard Time",
      "Pacific/Guadalcanal",
      "Antarctica/Macquarie",
      "Pacific/Ponape",
      "Pacific/Kosrae",
      "Pacific/Noumea",
      "Pacific/Efate",
      "Etc/GMT-11"
    ]
  },
  {
    "displayName": "Anadyr, Petropavlovsk-Kamchatsky",
    "windowsZone": "Russia Time Zone 11",
    "ianaZone": "Asia/Kamchatka",
    "zones": [
      "Russia Time Zone 11",
      "Asia/Kamchatka",
      "Asia/Anadyr"
    ]
  },
  {
    "displayName": "Auckland, Wellington",
    "windowsZone": "New Zealand Standard Time",
    "ianaZone": "Pacific/Auckland",
    "zones": [
      "New Zealand Standard Time",
      "Pacific/Auckland",
      "Antarctica/McMurdo"
    ]
  },
  {
    "displayName": "Coordinated Universal Time+12",
    "windowsZone": "UTC+12",
    "ianaZone": "Etc/GMT-12",
    "zones": [
      "UTC+12",
      "Etc/GMT-12",
      "Pacific/Tarawa",
      "Pacific/Majuro",
      "Pacific/Kwajalein",
      "Pacific/Nauru",
      "Pacific/Funafuti",
      "Pacific/Wake",
      "Pacific/Wallis"
    ]
  },
  {
    "displayName": "Fiji",
    "windowsZone": "Fiji Standard Time",
    "ianaZone": "Pacific/Fiji",
    "zones": [
      "Fiji Standard Time",
      "Pacific/Fiji"
    ]
  },
  {
    "displayName": "Chatham Islands",
    "windowsZone": "Chatham Islands Standard Time",
    "ianaZone": "Pacific/Chatham",
    "zones": [
      "Chatham Islands Standard Time",
      "Pacific/Chatham"
    ]
  },
  {
    "displayName": "Coordinated Universal Time+13",
    "windowsZone": "UTC+13",
    "ianaZone": "Etc/GMT-13",
    "zones": [
      "UTC+13",
      "Etc/GMT-13",
      "Pacific/Enderbury",
      "Pacific/Fakaofo"
    ]
  },
  {
    "displayName": "Nuku'alofa ",
    "windowsZone": "Tonga Standard Time",
    "ianaZone": "Pacific/Tongatapu",
    "zones": [
      "Tonga Standard Time",
      "Pacific/Tongatapu",
      "Pacific/Apia"
    ]
  },
  {
    "displayName": "Samoa",
    "windowsZone": "Samoa Standard Time",
    "ianaZone": "Pacific/Apia",
    "zones": [
      "Samoa Standard Time",
      "Pacific/Apia"
    ]
  },
  {
    "displayName": "Kiritimati Island",
    "windowsZone": "Line Islands Standard Time",
    "ianaZone": "Pacific/Kiritimati",
    "zones": [
      "Line Islands Standard Time",
      "Pacific/Kiritimati",
      "Etc/GMT-14"
    ]
  }
];

function getTimezones() {
    return timezones;
}

function guessTimezone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone || "Etc/GMT";
  return dayjs.tz.guess();
}

function getTimestamp() {
  return Date.now();
  return dayjs.utc().valueOf();
}

function parseDateTime(input, format, timezone = "Etc/GMT") {
  if (timezone == undefined)
    return dayjs.utc(input, format).valueOf();
  else
    return dayjs.tz(input, format, timezone).utc().valueOf();
}

function localeDateTime(timestamp, options, locale = Intl.NumberFormat().resolvedOptions().locale || "en-US", timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone || "Etc/GMT") {
  return new Date(isNaN(timestamp) ? timestamp : parseInt(timestamp)).toLocaleString(locale, { timeZone: timeZone, ...options });
}

function localeDateTimeRange(timestamp1, timestamp2, options, locale = Intl.NumberFormat().resolvedOptions().locale || "en-US", timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone || "Etc/GMT") {
  return new Intl.DateTimeFormat(locale, { timeZone: timeZone, ...options }).formatRange(isNaN(timestamp1) ? timestamp1 : parseInt(timestamp1), isNaN(timestamp2) ? timestamp2 : parseInt(timestamp2));
}

function localeDateTimeDifference(timestamp1, timestamp2, options = { style: "long" }, locale = Intl.NumberFormat().resolvedOptions().locale || "en-US") {
  const dt1 = new Date(timestamp1);
  const dt2 = new Date(timestamp2);

  return new DurationFormat(locale, options).format({
    years: Math.abs(dt1.getFullYear() - dt2.getFullYear()),
    months: Math.abs(dt1.getMonth() - dt2.getMonth()),
    days: Math.abs(dt1.getDate() - dt2.getDate()),
    hours: Math.abs(dt1.getHours() - dt2.getHours()),
    minutes: Math.abs(dt1.getMinutes() - dt2.getMinutes()),
    seconds: Math.abs(dt1.getSeconds() - dt2.getSeconds())
  });
}

function localeRelativeDateTime(timestamp, options = { style: "long" }, locale = Intl.NumberFormat().resolvedOptions().locale || "en-US") {
  const durationFormat = new DurationFormat(locale, options);
  const tsNow = Date.now();
  const dt1 = new Date(timestamp);
  const dt2 = new Date(tsNow);

  const parts = [];

  if (timestamp - tsNow > 0) parts.push("in");

  parts.push(durationFormat.format({
    years: Math.abs(dt1.getFullYear() - dt2.getFullYear()),
    months: Math.abs(dt1.getMonth() - dt2.getMonth()),
    days: Math.abs(dt1.getDate() - dt2.getDate()),
    hours: Math.abs(dt1.getHours() - dt2.getHours()),
    minutes: Math.abs(dt1.getMinutes() - dt2.getMinutes())
  }));

  if (timestamp - tsNow < 0) parts.push("ago");

  return parts.join(" ");
}

function formatTimestamp(timestamp, format, timezone = "Etc/GMT") {
  return dayjs.utc(timestamp).tz(timezone).format(format);
}

function localeFormatDateTime(timestamp, opts = {}) {
  return new Intl.DateTimeFormat(
    opts.locale || Intl.DateTimeFormat().resolvedOptions().locale || window?.navigator?.language || "en-US", 
    { 
      dateStyle: opts.dateStyle || "short",
      timeStyle: opts.timeStyle || "short",
      timeZone: opts.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone || guessTimezone() || "Etc/GMT"
    }
  ).format(timestamp);
}

function addToTimestamp(timestamp, value, unit) {
  return dayjs.utc(timestamp).add(value, unit).valueOf();
  const dt = new Date(parseInt(timestamp));
  if (unit.toLowerCase() == "year" || unit.toLowerCase() == "years") return dt.setFullYear(dt.getFullYear() + parseInt(value));
  if (unit.toLowerCase() == "month" || unit.toLowerCase() == "months") return dt.setMonth(dt.getMonth() + parseInt(value));
  if (unit.toLowerCase() == "day" || unit.toLowerCase() == "days") return dt.setDate(dt.getDate() + parseInt(value));
  if (unit.toLowerCase() == "hour" || unit.toLowerCase() == "hours") return dt.setHours(dt.getHours() + parseInt(value));
  if (unit.toLowerCase() == "minute" || unit.toLowerCase() == "minutes") return dt.setMinutes(dt.getMinutes() + parseInt(value));
  if (unit.toLowerCase() == "second" || unit.toLowerCase() == "seconds") return dt.setSeconds(dt.getSeconds() + parseInt(value));
  if (unit.toLowerCase() == "millisecond" || unit.toLowerCase() == "milliseconds") return dt.setMilliseconds(dt.getMillisetMilliseconds() + parseInt(value));
  throw new Error(`unknown unit: ${unit}`);
}

function subtractFromTimestamp(timestamp, value, unit) {
  return dayjs.utc(timestamp).subtract(value, unit).valueOf();
  const dt = new Date(parseInt(timestamp));
  if (unit.toLowerCase() == "year" || unit.toLowerCase() == "years") return dt.setFullYear(dt.getFullYear() - parseInt(value));
  if (unit.toLowerCase() == "month" || unit.toLowerCase() == "months") return dt.setMonth(dt.getMonth() - parseInt(value));
  if (unit.toLowerCase() == "day" || unit.toLowerCase() == "days") return dt.setDate(dt.getDate() - parseInt(value));
  if (unit.toLowerCase() == "hour" || unit.toLowerCase() == "hours") return dt.setHours(dt.getHours() - parseInt(value));
  if (unit.toLowerCase() == "minute" || unit.toLowerCase() == "minutes") return dt.setMinutes(dt.getMinutes() - parseInt(value));
  if (unit.toLowerCase() == "second" || unit.toLowerCase() == "seconds") return dt.setSeconds(dt.getSeconds() - parseInt(value));
  if (unit.toLowerCase() == "millisecond" || unit.toLowerCase() == "milliseconds") return dt.setMilliseconds(dt.getMillisetMilliseconds() - parseInt(value));
  throw new Error(`unknown unit: ${unit}`);
}

function startOfTimestamp(timestamp, unit) {
  return dayjs.utc(timestamp).startOf(unit).valueOf();
}

function getDayOfWeek(timestamp) {
  return dayjs.utc(timestamp).day();
  return new Date(parseInt(timestamp)).getDay();
}

function getDaysInMonth(timestamp) {
  return dayjs.utc(timestamp).daysInMonth();
  const dt = new Date(parseInt(timestamp));
  return new Date(dt.getFullYear(), dt.getMonth(), 0).getDate();
}

function formatDuration(duration, format) {
  return dayjs
    .duration(duration, "minutes")
    .format(format
      .replace(/([.+?])?HHH([.+?])?/gi, `[${Math.floor(duration / 60)}]`)
      .replace(/([.+?])?HHHH([.+?])?/gi, `[${Math.ceil(duration / 60)}]`)
      .replace(/([.+?])?mmm([.+?])?/gi, `[${duration - (Math.floor(duration / 60) * 60)}]`)
      .replace(/([.+?])?mmmm([.+?])?/gi, `[${duration}]`)
    );
}

function humanizeDuration(duration, lang = "en-US") {
  const rtf = new Intl.RelativeTimeFormat(lang, { style: 'long' });
  var parts = [];
  var absoluteDuration = Math.abs(duration);
  var months = Math.floor(absoluteDuration / 40320);
  if (months > 0) parts.push(rtf.formatToParts(months, "months").slice(1).map(o => o.value).join(""));
  var weeks = Math.floor((absoluteDuration - (months * 40320)) / 10080);
  if (weeks > 0) parts.push(rtf.formatToParts(weeks, "weeks").slice(1).map(o => o.value).join(""));
  var days = Math.floor((absoluteDuration - (months * 40320) - (weeks * 10080)) / 1440);
  if (days > 0) parts.push(rtf.formatToParts(days, "days").slice(1).map(o => o.value).join(""));
  var hours = Math.floor((absoluteDuration - (months * 40320) - (weeks * 10080) - (days * 1440)) / 60);
  if (hours > 0) parts.push(rtf.formatToParts(hours, "hours").slice(1).map(o => o.value).join(""));
  var minutes = absoluteDuration - (months * 40320) - (weeks * 10080) - (days * 1440) - (hours * 60);
  if (minutes > 0) parts.push(rtf.formatToParts(minutes, "minutes").slice(1).map(o => o.value).join(""));
  
  if (parts.length == 0) return "immediately";
  if (absoluteDuration != duration) {
    return `${rtf.formatToParts(1, "minutes")[0]} ${parts.join(",")}`;
  } else {
    return parts.join(", ");
  }
  
  if (relative) {
    if (parts.length == 0) return `now`;
    if (absoluteDuration == duration) return `in ${parts.join(", ")}`;
    return `${parts.join(", ")} ago`;
  } else {
    if (parts.length == 0) return `immediately`;
    return parts.join(", ");
  }

  return dayjs.duration(duration, "minutes").humanize();
}

function localeDuration(duration, options = { style: "long" }, locale = Intl.NumberFormat().resolvedOptions().locale || "en-US") {
  /*const tsNow = Date.now();
  const dt1 = new Date(tsNow);
  const dt2 = new Date(tsNow);
  dt2.setMinutes(dt2.getMinutes() + duration);

  return new Intl.DurationFormat(locale, options).format({
    years: Math.abs(dt1.getFullYear() - dt2.getFullYear()),
    months: Math.abs(dt1.getMonth() - dt2.getMonth()),
    days: Math.abs(dt1.getDate() - dt2.getDate()),
    hours: Math.abs(dt1.getHours() - dt2.getHours()),
    minutes: Math.abs(dt1.getMinutes() - dt2.getMinutes()),
    seconds: Math.abs(dt1.getSeconds() - dt2.getSeconds())
  });*/

  const rtf = new Intl.RelativeTimeFormat(locale, options);
  const absoluteDuration = Math.abs(parseInt(duration));
  const parts = [];

  const months = Math.floor(absoluteDuration / 40320);
  if (months > 0) parts.push(rtf.formatToParts(months, "months").slice(1).map(o => o.value).join(""));

  const weeks = Math.floor((absoluteDuration - (months * 40320)) / 10080);
  if (weeks > 0) parts.push(rtf.formatToParts(weeks, "weeks").slice(1).map(o => o.value).join(""));

  const days = Math.floor((absoluteDuration - (months * 40320) - (weeks * 10080)) / 1440);
  if (days > 0) parts.push(rtf.formatToParts(days, "days").slice(1).map(o => o.value).join(""));

  const hours = Math.floor((absoluteDuration - (months * 40320) - (weeks * 10080) - (days * 1440)) / 60);
  if (hours > 0) parts.push(rtf.formatToParts(hours, "hours").slice(1).map(o => o.value).join(""));

  const minutes = absoluteDuration - (months * 40320) - (weeks * 10080) - (days * 1440) - (hours * 60);
  if (minutes > 0) parts.push(rtf.formatToParts(minutes, "minutes").slice(1).map(o => o.value).join(""));
  
  if (parts.length == 0) parts.push(rtf.formatToParts(0, "minutes").slice(1).map(o => o.value).join(""));

  return parts.join(", ");
}

function getDurationHours(duration) {
  return dayjs.duration(duration, "minutes").asHours();
}

function getDurationMinutes(duration) {
  return dayjs.duration(duration, "minutes").asMinutes();
}

function getTimezoneOffset(atTime, timezone) {
  if (typeof atTime === "string") atTime = new Date(atTime);
  const localizedTime = new Date(atTime.toLocaleString("en-US", {timezone}));
  const utcTime = new Date(atTime.toLocaleString("en-US", {timeZone: "UTC"}));
  return Math.round((localizedTime.getTime() - utcTime.getTime()) / (60 * 1000));
  return dayjs().tz(timezone).toDate().getTimezoneOffset()
}

module.exports = {
  timezones,
  getTimezones,
  getTimezoneOffset,
  guessTimezone,
  getTimestamp,
  parseDateTime,
  formatTimestamp,
  addToTimestamp,
  subtractFromTimestamp,
  startOfTimestamp,
  getDayOfWeek,
  getDaysInMonth,
  formatDuration,
  humanizeDuration,
  getDurationHours,
  getDurationMinutes,
  localeFormatDateTime,
  localeDateTime,
  localeDateTimeRange,
  localeDuration,
  localeDateTimeDifference,
  localeRelativeDateTime
}