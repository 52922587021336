import { LZSPAComponent, LZSPARouter } from "@eventbuilder-utils/lzspa";
import { setupTinyMCEEditors } from "../utils/tinymce";
import { EBUploadManager, EBUploader } from "../utils/uploads";

export default class EBDefaultLayout extends LZSPAComponent {
    basePath = "/";

    constructor(params, opts = {}) {
        super(params);

        this.element.className = ["page", ...opts.className?.split(" ") || []].join(" ");
        
        this.element.innerHTML = `<div class="page__banner"></div>
            <div class="page__header">
                <h1>${opts.title || ""}</h1>
                <p>${opts.subtitle || ""}</p>
                <div class="page__controls"></div>
                <div class="divider"></div>
            </div>
            <div class="page__content">${opts.content || ""}</div>`;

        this.basePath = opts.basePath || new URL(window.location.href).pathname;

        for (let control of (opts?.controls || [])) {
            this.addControl(control.name, control);
        }
    }

    get lzFirstInvalidElement() { 
        return this.lzGetElements(`input[data-lzproperty][required]:invalid, select[data-lzproperty][required]:invalid, textarea[data-lzproperty][required]:invalid`).find(element => element.closest(`.hidden`) == undefined) || null;
    }

    lzSetFocusOnFirstInvalidElement() {
        const element = this.lzFirstInvalidElement;
        if (element.tagName == "TEXTAREA") {
            const tinyEditor = element.nextSibling?.querySelector(".tox-edit-area > iframe")?.contentWindow?.document?.body;
            if (tinyEditor) {
                tinyEditor.focus();
                tinyEditor.reportValidity();
            } else {
                element.focus();
                element.reportValidity();
            }
        } else {
            element.focus();
            element.reportValidity();
        }
    }

    async lzOnInit(params) {
        await super.lzOnInit(params);

        setupTinyMCEEditors(this.element, (callback, value, meta) => this.onTinyMCEFilePicker.call(this, callback, value, meta));
    }

    async dispose() {
        return super.dispose();
    }

    get banner() {
        return this.lzGetElement("& > div.page__banner > img")?.src;
    }
    
    set banner(value) { 
        const element = this.lzGetElement("& > div.banner");
        if (!!!value) {
            element.children.forEach(c => c.remove());
        } else {
            if (element.children.length != 0) {
                element.children[0].src = value;
            } else {
                const img = element.appendChild(document.createElement("img"));
                img.src = value;
                img.setAttribute("aria-hidden", "true");
            }
        }
    }
    
    get title() {
        return this.lzGetElement("& > div.page__header > h1").innerText;
    }
    
    set title(value) {
        this.lzGetElement("& > div.page__header > h1").innerText = value || "";
    }

    get subtitle() {
        return this.lzGetElement("& > div.page__header > p").innerText;
    }
    
    set subtitle(value) {
        this.lzGetElement("& > div.page__header > p").innerText = value || "";
    }

    get controls() {
        return [...this.lzGetElements("& > div.page__header > div.page__controls > button")];
    }

    control(name) {
        return this.lzGetElement(`& > div.page__header > div.page__controls > button[data-ebcontrol="${name}"]`);
    }

    addControl(name, opts = {}) {
        const button = this.lzGetElement("& > div.page__header > div.page__controls").appendChild(document.createElement("button"));
        button.setAttribute("role", "button");
        button.setAttribute("title", opts.label || name);
        button.setAttribute("aria-label", opts.ariaLabel || opts.label || name);
        button.setAttribute("data-ebcontrol", name);
        button.setAttribute("class", "btn");
        if (opts.className) button.classList.add(opts.className.split(" "));
        if (!!opts.disabled) button.disabled = true;
        button.innerHTML = `<svg class="toggle" data-src="/assets/images/${opts.icon || name.toLowerCase().replace(/ /g, "-")}.svg" aria-hidden="true"></svg><span aria-hidden="true">${opts.label || name}</span>`;
        return button;
    }

    get content() {
        return this.contentElement.innerHTML;
    }

    set content(value) {
        if (value instanceof HTMLElement) {
            this.contentElement.replaceWith(value);
            this.contentElement.classList.add("page__content");
        } else if (typeof value === "string") {
            this.contentElement.innerHTML = value;
        } else {
            throw new Error("Unknown content type");
        }
    }

    get contentElement() {
        return this.lzGetElement(`& > div.page__content`);
    }

    async onTinyMCEFilePicker(callback, value, meta) {
        try {
            const file = await EBUploader.openDialog();
            if (file == undefined) return;

            const result = await EBUploader.uploadFile(file, `${this.basePath}/uploads/public`, document.querySelector(`div.tox div.tox-dialog input[type="url"]`));
    
            callback(result);
        } catch (ex) {
            console.error(ex);
            showErrorToast(ex.message || ex);
        }

        /*EBUploadManager.open(`${this.basePath}/uploads/public/`).then(result => {
            if (result == undefined) return;
            callback(result);
        }).catch(ex => { console.error(ex); showErrorToast(ex.message || ex); });*/
    }
}