import { joinPathClean } from "@eventbuilder-utils/path";
import { EBSession } from "./session";
import { showErrorToast } from "./toast";

function convertStringToArrayBuffer(str) {
    const textEncoder = new TextEncoder();
    return textEncoder.encode(str).buffer;
  };
  
function convertFileToArrayBuffer(file) {
    return new Promise((resolve, reject) => {
      if (!file || !file.name) {
        reject(new Error('Invalid or missing file.'));
      }
  
      const reader = new FileReader();
  
      reader.onload = () => {
        const arrayBuffer = reader.result;
  
        if (arrayBuffer === null) {
          resolve(null);
          return;
        }

        if (typeof arrayBuffer === 'string') {
          resolve(convertStringToArrayBuffer(arrayBuffer));
          return;
        }

        if (!arrayBuffer) {
          reject(new Error('Failed to read file into ArrayBuffer.'));
          return;
        }
  
        resolve(arrayBuffer);
      };
  
      reader.onerror = () => {
        reject(new Error('Error reading file.'));
      };
  
      reader.readAsArrayBuffer(file);
    });
}

export class EBUploader extends EventTarget {

    static openDialog(acceptTypes) {
        return new Promise((resolve, reject) => {
            const el = document.createElement("input");
            el.type = "file";
            el.multiple = false;
            if (acceptTypes) el.accept = acceptTypes;
            el.addEventListener("change", (event) => {
                if (event.target.files.length == 0) return resolve(undefined);
                return resolve(event.target.files[0]);
            });
            el.click();
        });
    }

    static async uploadFile(file, path, progressElement) {
        var progress = 0;
        try {
            //const uploadInfo = await EBSession.post(joinPathClean("/api/v3", path, `${Date.now()}${Math.ceil(Math.random() * 100000)}${/(?:(\.[^.]+))?$/.exec(file.name)[1] || ""}`));
            const uploadInfo = await EBSession.post(joinPathClean("/api/v3", path, `${crypto.randomUUID().replace(/-/g, "")}${/(?:(\.[^.]+))?$/.exec(file.name)[1] || ""}`));

            const xhr = new XMLHttpRequest();
            
            return new Promise((resolve) => {
              xhr.upload.addEventListener("progress", (event) => {
                if (event.lengthComputable) {
                    progress = Math.ceil((event.loaded / event.total) * 100);
                    console.log(`uploading '${file.name}': ${progress}%`);
                    
                    if (progressElement) {
                        try {
                            progressElement.style.setProperty("background", `linear-gradient(90deg, var(--input-border) ${progress}%, var(--input-background) ${progress}%)`);
                        } catch (ex) {
                            console.error(ex);
                        }
                    }
                }
              });
              xhr.addEventListener("progress", (event) => {
                if (event.lengthComputable) {
                    console.log("download progress:", event.loaded / event.total);
                }
              });
              xhr.addEventListener("loadend", () => {
                if (xhr.readyState === 4 && xhr.status === 201) {
                    if (progressElement) {
                        try {
                            progressElement.style.removeProperty("background");
                        } catch (ex) {
                            console.error(ex);
                        }
                    }
                    resolve(uploadInfo.retrieveUrl);
                } else {
                    if (progressElement) {
                        try {
                            progressElement.style.setProperty("background", `linear-gradient(90deg, var(--input-invalid-border) ${progress}%, var(--input-background) ${progress}%)`);
                        } catch (ex) {
                            console.error(ex);
                        }
                    }
                    resolve(undefined);
                }
              });
              xhr.open("PUT", uploadInfo.uploadUrl, true);
              xhr.setRequestHeader("Content-Type", file.type);
              xhr.setRequestHeader("x-ms-blob-content-type", file.type);
              xhr.setRequestHeader("x-ms-blob-content-disposition", `attachment;filename="${file.name}"`);
              xhr.setRequestHeader("x-ms-blob-type", "BlockBlob");
              xhr.send(file);
            });
        } catch (ex) {
            if (progressElement) {
                try {
                    progressElement.style.setProperty("background", `linear-gradient(90deg, var(--colorError) ${progress}%, var(--colorControlBackground) ${progress}%)`);
                } catch (ex) {
                    console.error(ex);
                }
            }
            throw ex;
        }
    }

    constructor() {
        super();

        const xhr = new XMLHttpRequest();
        xhr.upload.addEventListener("progress", (event) => {
            if (!event.lengthComputable) return;
            this.dispatchEvent(new CustomEvent("progress", { detail: Math.ceil((event.loaded / event.total) * 100), cancelable: false, bubbles: false }));
        });
        xhr.addEventListener("progress", (event) => {
            if (!event.lengthComputable) return;
            this.dispatchEvent(new CustomEvent("progress", { detail: Math.ceil((event.loaded / event.total) * 100), cancelable: false, bubbles: false }));
        });
        xhr.addEventListener("loadend", () => {
            if (xhr.readyState === 4 && xhr.status >= 200 && xhr.status < 400) {
                this.dispatchEvent(new CustomEvent("done", { detail: this.content, cancelable: false, bubbles: false }));
            } else {
                this.dispatchEvent(new CustomEvent("error", { detail: xhr.statusText, cancelable: false, bubbles: false }));
            }
        });

        this.xhr = xhr;
    }

    async upload(file, path) {
        const uploadInfo = await EBSession.post(joinPathClean("/api/v3", path, `${crypto.randomUUID().replace(/-/g, "")}${/(?:(\.[^.]+))?$/.exec(file.name)[1] || ""}`));
        this.content = uploadInfo.retrieveUrl;
        xhr.open("PUT", uploadInfo.uploadUrl, true);
        xhr.setRequestHeader("Content-Type", file.type);
        xhr.setRequestHeader("x-ms-blob-content-type", file.type);
        xhr.setRequestHeader("x-ms-blob-content-disposition", `attachment;filename="${file.name}"`);
        xhr.setRequestHeader("x-ms-blob-type", "BlockBlob");
        xhr.send(file);
    }
}

export const EBUploadManager = new class extends EventTarget {
    #pending = [];

    async open(path, accept) {
        return new Promise((resolve, reject) => {
            const el = document.createElement("input");
            el.type = "file";
            el.multiple = false;
            if (accept)
                el.accept = accept;
            el.addEventListener("change", (event) => {
                if (event.target.files.length == 0) return resolve(undefined);

                const instance = new EBFileUploader(event.target.files[0], path);
                
                const notificationsElement = document?.querySelector("#notificationmenu");
                
                instance.addEventListener("eb:uploader.progress", (event) => {
                    document.querySelector(`#notificationmenu > div[data-url="${instance.uploadUrl}"]`).innerHTML = `<b>Uploading ${instance.name}</b><br><progress value="${instance.progress}" max="100"> ${instance.progress}% </progress>`;
                });
                instance.addEventListener("eb:uploader.error", (event) => {
                    const notificationElement = document.querySelector(`#notificationmenu > div[data-url="${instance.uploadUrl}"]`)
                    if (notificationElement != undefined) {
                        notificationElement.innerHTML = `<b>Error Uploading ${instance.name}</b><br>${event.detail}<br><button>retry</button>`;
                        notificationElement.querySelector("button").addEventListener("click", (event) => instance.retryUpload());
                    }
                });
                instance.addEventListener("eb:uploader.completed", (event) => {
                    document.querySelector(`#notificationmenu > div[data-url="${instance.uploadUrl}"]`).innerHTML = `<b>Uploaded ${instance.name}!</b>`;
                    resolve(event.detail);
                });

                if (notificationsElement != undefined) {
                    const notificationElement = notificationsElement.insertAdjacentElement("afterbegin", document.createElement("div"));
                    notificationElement.setAttribute("data-url", instance.uploadUrl);
                    notificationElement.innerHTML = `<b>Uploading ${instance.name}</b><br><progress value="0" max="100"> 0% </progress>`;
                }

                instance.beginUpload();
            });
            el.click();
        });
    }
}

class EBFileUploader extends EventTarget {
    #file;
    #uploadUrl;
    #resultUrl;

    #chunkSize = 1000000;
    #chunks = [];

    get name() { return this.#file.name; }
    get uploadUrl() { return this.#uploadUrl; }
    get url() { return this.#resultUrl; }
    get progress() { return Math.ceil((this.#chunks.filter(status => status == true).length / this.#chunks.length) * 100); }

    get status() {
        if (this.#chunks.find(status => status != false && status != true)) return "error";
        if (this.#chunks.find(status => status == false)) return "uploading";
        return "completed";
    }
    
    constructor(file, path = "") {
        super();
        this.#file = file;
        this.#uploadUrl = `/api/v3/${joinPathClean(path, file.name)}`;

        const chunks = Math.ceil(file.size / this.#chunkSize);
        for (let i = 0; i < chunks; i++)
            this.#chunks.push(false);
    }

    async beginUpload() {
        for (let i = 0; i < this.#chunks.length; i++) {
            await this.#uploadChunk(i);
        }
    }

    async retryUpload() {
        if (this.#chunks.find(status => status != true) == undefined) {
            await this.#commitChunks();
        } else {
            for (let i = 0; i < this.#chunks.length; i++) {
                if (this.#chunks[i] == true) continue;
                await this.#uploadChunk(i);
            }
        }
    }

    async #uploadChunk(index = 0) {
        try {
            if (this.#chunks[index] == undefined) throw new Error("invalid index");

            await EBSession.upload(
                new URL(`${this.#uploadUrl}?method=stage&chunk=chunk${index}`, window.location.origin).toString(),
                this.#file.slice(index * this.#chunkSize, Math.min((index * this.#chunkSize) + this.#chunkSize, this.#file.size))
            );
            this.#chunks[index] = true;
        } catch(ex) {
            console.error(ex);
            this.#chunks[index] = ex.message || ex;
            this.dispatchEvent(new CustomEvent("eb:uploader.error", { cancelable: false, bubbles: true, detail: ex.message || ex}));
        } finally {
            this.dispatchEvent(new CustomEvent("eb:uploader.progress", { cancelable: false, bubbles: true, detail: this}));
            if (this.#chunks.find(status => status != true) == undefined) this.#commitChunks();
        }
    }

    async #commitChunks() {
        try {
            const result = await EBSession.create(
                new URL(`${this.#uploadUrl}?method=commit`, window.location.origin).toString(),
                this.#chunks.map((status, index) => `chunk${index}`)
            );
            this.dispatchEvent(new CustomEvent("eb:uploader.completed", { cancelable: false, bubbles: true, detail: result.url}));
        } catch(ex) {
            console.error(ex);
            this.dispatchEvent(new CustomEvent("eb:uploader.error", { cancelable: false, bubbles: true, detail: ex.message || ex}));
            throw ex;
        }
    }
}