import { WebPubSubClient } from "@azure/web-pubsub-client/dist/index";

class EventEmitter {
    #events;

    constructor() {
        this.#events = {};
    }

    on(event, listener) {
        if (typeof this.#events[event] !== 'object') {
            this.#events[event] = [];
        }
        this.#events[event].push(listener);
    }

    removeListener(event, listener) {
        var idx;
        if (typeof this.#events[event] === 'object') {
            idx = indexOf(this.#events[event], listener);
            if (idx > -1) {
                this.#events[event].splice(idx, 1);
            }
        }
    }

    emit(event) {
        var i, listeners, length, args = [].slice.call(arguments, 1);
        if (typeof this.#events[event] === 'object') {
            listeners = this.#events[event].slice();
            length = listeners.length;
            for (i = 0; i < length; i++) {
                listeners[i].apply(this, args);
            }
        }
    }

    once(event, listener) {
        this.on(event, function g () {
            this.removeListener(event, g);
            listener.apply(this, arguments);
        });
    };

}

class PubSubClient extends EventEmitter {
    #url;
    #clientType;
    #client;

    get url() { return this.#url; }
    get clientType() { return this.#clientType; }

    async connect(url) {
        this.#url = url;
        this.#clientType = "websocket";
        this.#client = undefined;

        if (/.+\.webpubsub\.azure\.com/.test(url)) {
            this.#clientType = "azurepubsub";
        } else {
            this.#clientType = "websocket";
        }

        if (this.#clientType == "azurepubsub") {
            this.#client = new WebPubSubClient(this.#url, { });
            this.#client.onopen = (ev) => { console.log("connected"); this.emit("connected", ev); };
            this.#client.onclose = (ev) => { console.log("disconnected"); this.emit("disconnected", ev); };
            this.#client.onerror = (err) => { console.error(err); this.emit("error", err); };
            this.#client.on("connected", (event) => { console.log("connected"); this.emit("connected", event); });
            this.#client.on("disconnected", (event) => { console.log("disconnected"); this.emit("disconnected", event); });
            this.#client.on("group-message", (event) => { console.log(event.message.data); this.emit("message", event.message.group, event.message.data); });
            this.#client.on("server-message", (event) => { console.log(event); this.emit("message", undefined, event.message.data); });
            this.#client.on("stopped", (event) => { console.log("disconnected"); this.emit("disconnected", event); });
            //this.#client.on("rejoin-group-failed", (e) => this.emit("message", e.message.data, undefined));
            //this.#client.on("stopped", (e) => this.emit("message", e.message.data, undefined));
            await this.#client.start();
        } else if (this.#clientType == "websocket") {
            this.#client = new WebSocket(this.#url);
            this.#client.binaryType = "arraybuffer";
            this.#client.onopen = (event) => {
                this.emit("connected", event);
            }
            this.#client.onclose = (event) => {
                this.emit("disconnected", event);
            }
            this.#client.onerror = (event) => {
                console.error(event);
                setTimeout(() => {
                    this.connect(this.#url);
                }, 1000);
            };
            this.#client.onmessage = (event) => {
                let message = JSON.parse(event.data);
                if (!message.group) {
                    this.emit("message", undefined, message.data);
                } else {
                    this.emit("message", message.group, message.data);
                }
            }
        }
    }
    
    disconnect() {
        if (this.#clientType == "azurepubsub") {
            this.#client.stop();
        } else if (this.#clientType == "websocket") {
            this.#client.close();
        }
    }
}

const instance = new PubSubClient();
export default instance;

/*
class EventEmitter {
    constructor() {
        this.callbacks = {};
    }

    on(event, cb) {
        if(!this.callbacks[event]) this.callbacks[event] = [];
        this.callbacks[event].push(cb);
    }

    off(event, cb) {

    }

    emit(event, ...args) {
        let cbs = this.callbacks[event];
        if(cbs) {
            cbs.forEach(cb => cb.call(this, ...args));
        }
    }
}

export default class PubSubClient extends EventEmitter {
    constructor(url) {
        super();
        this._url = url;
        this._clientType = "websocket";
        this._client = undefined;
        this._retries = 0;

        if (/.+\.webpubsub\.azure\.com/.test(url)) {
            this._clientType = "azurepubsub";
        } else {
            this._clientType = "websocket";
        }

        this.connect = () => {
            if (this._clientType == "azurepubsub") {
                this._client = new WebPubSubClient(this._url);
                this._client.on("connected", (event) => this.emit("connected", event));
                this._client.on("disconnected", (event) => this.emit("disconnected", event));
                this._client.on("group-message", (event) => this.emit("group-message", event.message.group, event.message.data));
                this._client.on("server-message", (event) => this.emit("server-message", event.message.data));
                //this._client.on("rejoin-group-failed", (e) => this.emit("message", e.message.data, undefined));
                //this._client.on("stopped", (e) => this.emit("message", e.message.data, undefined));
                this._client.start();
            } else if (this._clientType == "websocket") {
                this._client = new WebSocket(this._url);
                this._client.binaryType = "arraybuffer";
                this._client.onopen = (event) => {
                    this._retries = 0;
                    this.emit("connected", event);
                }
                this._client.onclose = (event) => {
                    this.emit("disconnected", event);
                }
                this._client.onerror = (event) => {
                    console.error(event);
                    setTimeout(() => {
                        retries++;
                        connect();
                    }, 1000);
                };
                this._client.onmessage = (event) => {
                    let message = JSON.parse(event.data);
                    if (!message.group) {
                        this.emit("server-message", message.data);
                    } else {
                        this.emit("group-message", message.group, message.data);
                    }
                }
            }
        }
        
        this.disconnect = () => {
            if (this._clientType == "azurepubsub") {
                this._client.stop();
            } else if (this._clientType == "websocket") {
                this._client.close();
            }
        }
    
        this.sendGroupMessage = (groupName, content) => {
            if (!content || !groupName) throw new Error("groupName and content are required");
            if (this._clientType == "azurepubsub") {
                this._client.sendToGroup(groupName, content);
            } else if (this._clientType == "websocket") {
                this._client.send(JSON.stringify({ group: groupName, data: content }));
            }
        }

        this.connect();
    }
}*/