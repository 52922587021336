class DropdownButton extends HTMLElement {

    constructor() {
        super();
        
        this.style.setProperty("display", "none");
        this.style.setProperty("flex-direction", "column");
        this.style.setProperty("position", "fixed");

        for (let child of this.children) {
            child.addEventListener("click", (event) => {
                this.style.setProperty("display", "none");
            });
        }

        const buttonElement = document.createElement("button");
        buttonElement.setAttribute("type", "button");
        buttonElement.setAttribute("title", "more actions");
        buttonElement.setAttribute("aria-label", "more actions");
        buttonElement.innerHTML = `<svg data-src="${this.getAttribute("eb-icon") || `/assets/images/three-dots.svg`}"></svg>`;
        buttonElement.addEventListener("click", (event) => {
            this.style.setProperty("display", "flex");
            this.style.setProperty("right", `${window.innerWidth - event.pageX}px`);
            this.style.setProperty("z-index", 10);
            if (event.pageY + this.clientHeight > window.innerHeight) {
                this.style.setProperty("top", `${event.pageY - this.clientHeight}px`);
            } else {
                this.style.setProperty("top", `${event.pageY}px`);
            }
        });
        this.insertAdjacentElement("beforebegin", buttonElement);

        document.addEventListener("click", (event) => {
            console.log("click2");
            if (!this.contains(event.target) && event.target !== buttonElement) this.style.setProperty("display", "none");
        });
    }
}

customElements.define('eb-dropdown-button', DropdownButton);