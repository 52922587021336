import { LZSPAObserver } from "@eventbuilder-utils/lzspa";
import { EBSession } from "../utils/session";
import { showErrorToast } from "../utils/toast";
import { debounce } from "../utils";
import FilterModal from "../modals/filter-modal";

export class EBSwitch extends HTMLButtonElement {
    constructor() {
        super();

        this.setAttribute("role", "switch");

        this.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" height="20" width="36">
            <rect class="container" x="1" y="1" width="34" height="18" rx="9"></rect>
            <rect class="off" x="4" y="4" width="12" height="12" rx="6"></rect>
            <rect class="on" x="20" y="4" width="12" height="12" rx="6"></rect>
        </svg>
        <span class="on" aria-hidden="true">${this.hasAttribute("data-ebontext") ? this.getAttribute("data-ebontext") : "Yes"}</span>
        <span class="off" aria-hidden="true">${this.hasAttribute("data-ebofftext") ? this.getAttribute("data-ebofftext") : "No"}</span>`;

        //this.addEventListener("click", () => this.toggleStatus());
    }

    toggleStatus() {
        const currentState = this.getAttribute('aria-checked') === 'true';
        const newState = String(!currentState);
        this.setAttribute('aria-checked', newState);
    }
}

customElements.define('eb-switch', EBSwitch, { extends: 'button' });