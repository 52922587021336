import { LZSPARouter } from "@eventbuilder-utils/lzspa";
import PubSubClient from "@eventbuilder-utils/pubsub-client";
import { Modal } from "../modals";
import EBModalLayout from "../layouts/modal";
import { showErrorToast } from "./toast";

class EBSessionHandler extends EventTarget {
    #baseUrl = null;
    #accessToken = null;
    #accessTokenExpires = null;
    #refreshToken = null;
    #refreshTokenExpires = null;
    #me = null;
    #tenant = null;
    #tenantId;

    #inactivityWarningModal;

    get account() { return this.#me.data; }
    get tenant() { return this.#tenant; }
    get valid() { return this.#me != null; }
    get accessToken() { return this.#accessToken; }
    get refreshToken() { return this.#refreshToken; }

    get features() { return this.#tenant.features; }
    get permissions() { return this.#tenant.permissions; }

    get OTPUrl() { return this.#me._links.otp; }

    constructor() {
        super();
        this.#baseUrl = window.location.origin;
        this.#accessToken = localStorage.getItem("at") || null;
        this.#accessTokenExpires = localStorage.getItem("ate") || null;
        this.#refreshToken = localStorage.getItem("rt") || null;
        this.#refreshTokenExpires = localStorage.getItem("rte") || null;
        this.sessionInactivityCheck();
    }

    sessionInactivityCheck() {
        try {
            if (this.#accessToken == null) return;

            var inactivityTimeout = window.localStorage.getItem("inactivityTimout");
            if (!!!inactivityTimeout) return;
            if (inactivityTimeout > Date.now()) {
                if (!!this.inactivityWarningModal) {
                    this.inactivityWarningModal.close();
                    this.inactivityWarningModal = undefined;
                }
                return;
            }

            if (!!!this.inactivityWarningModal) {
                this.inactivityWarningModal = new EBModalLayout();
                this.inactivityWarningModal.title = "Inactive";
                this.inactivityWarningModal.content = "You've been inactive for awhile. Click 'OK' to stay logged in."
                this.inactivityWarningModal
                    .addControl("OK", { icon: "check-circle" })
                    .addEventListener("click", (e) => {
                        this.inactivityWarningModal.close();
                        this.inactivityWarningModal = undefined;
                        this.resetInactivityTimer();
                    });
                this.inactivityWarningModal.show();
                window.localStorage.setItem("inactivityLogout", Date.now() + 60000);
                return;
            }

            var inactivityLogout = window.localStorage.getItem("inactivityLogout");
            if (!!!inactivityLogout) return;
            if (inactivityLogout > Date.now()) return;
            
            this.logout();
            alert("You've been logged out for inactivity.");
            window.location.reload();
        } catch (ex) {
            console.error(ex);
        } finally {
            setTimeout(() => this.sessionInactivityCheck(), 1000);
        }
    }

    resetInactivityTimer() {
        if (!!this.inactivityWarningModal) return;
        window.localStorage.setItem("inactivityTimout", Date.now() + 900000);
        window.localStorage.removeItem("inactivityLogout");
    }

    on(type, callback, options) {
        this.addEventListener(type, callback, options);
        return this;
    }

    async initialize() {
        if (new URL(window.location.href).searchParams.has("ak")) {
            const response = await fetch(new URL("/api/v3/token", this.#baseUrl), {
                method: "POST",
                headers: {
                    Authorization: `EBAccountKey ${new URL(window.location.href).searchParams.get("ak")}`
                }
            });
            if (response.status != 201) {
                try { this.logout(); } catch (ex) { console.error(ex); }
                console.error((await response.text()));
                throw new Error("session has expired", { cause: response });
            } else {
                const data = await response.json();
                this.#accessToken = data.accessToken;
                localStorage.setItem("at", this.#accessToken);
                this.#accessTokenExpires = data.accessTokenExpires;
                localStorage.setItem("ate", this.#accessTokenExpires);
                this.#refreshToken = data.refreshToken;
                localStorage.setItem("rt", this.#refreshToken);
                this.#refreshTokenExpires = data.refreshTokenExpires;
                localStorage.setItem("rte", this.#refreshTokenExpires);
            }
        }
        if (this.#accessToken !== null) {
            try {
                this.#me = await this.query(`/api/v3/me`);

                /*if (!this.tenant.setupWizardCompleted.value) {
                    const component = await import("../components/tenant-setup");
                    const modal = new component.default({ data: this.tenant }).initialize();
                    await modal.open();
                }*/
                
                this.dispatchEvent(new CustomEvent("eb:session.login"));

                await this.changeTenant(new URL(window.location.href).searchParams.get("t") || sessionStorage.getItem("t") || this.account.tenants[0].id);

                if (!this.account.setupComplete) {
                    const component = await import("../components/account-setup");
                    const modal = new component.default().initialize();
                    await modal.open();
                }

                if (this.account.passwordExpired) {
                    const component = await import("../modals/change-password");
                    await (new component.ChangePasswordModal(true));
                }

                this.resetInactivityTimer();

                await PubSubClient.connect(this.#me._links.realtime);
            } catch(ex) {
                console.error(ex);
                showErrorToast(ex.message || ex);
                this.logout();
            }
        }
        this.dispatchEvent(new CustomEvent("eb:session.initialized"));
    }

    async changeTenant(tenantId) {
        if (this.account.tenants.find(a => a.id == tenantId) == undefined) throw new Error("Invalid Tenant");

        this.#tenantId = tenantId;

        this.#tenant = (await this.query(`/api/v3/tenants/${tenantId}?expandProperties=true`)).data;
        this.#tenant.permissions = this.account.tenants.find(a => a.id == this.#tenant.id).permissions;

        /*if (!this.tenant.setupWizardCompleted.value) {
            const component = await import("../components/tenant-setup");
            const modal = new component.default({ data: this.tenant }).initialize();
            await modal.open();
        }*/

        sessionStorage.setItem("t", tenantId);

        this.dispatchEvent(new CustomEvent("eb:session.tenantchange"));
    }

    async login(email, password, otp = null) {
        this.dispatchEvent(new CustomEvent("eb:session.requeststart"));

        try {
            const response = await fetch(new URL("/api/v3/login", this.#baseUrl), {
                method: "POST",
                cache: "no-cache",
                body: JSON.stringify({
                    email: email,
                    password: password,
                    otp: otp
                })
            });
    
            if (response.status == 401) throw new Error((await response.text()) || "invalid credentials", { cause: response });
            if (response.status != 201) throw new Error((await response.text()) || response.statusText, { cause: response });
                
            const data = await response.json();

            if (!!data.v2 || response.headers.has("location")) {
                window.location.href = data.v2 || response.headers.get("location");
                return;
            }

            this.#accessToken = data.accessToken;
            localStorage.setItem("at", this.#accessToken);
            this.#accessTokenExpires = data.accessTokenExpires;
            localStorage.setItem("ate", this.#accessTokenExpires);
            this.#refreshToken = data.refreshToken;
            localStorage.setItem("rt", this.#refreshToken);
            this.#refreshTokenExpires = data.refreshTokenExpires;
            localStorage.setItem("rte", this.#refreshTokenExpires);
            this.#me = await this.query(`/api/v3/me`);

            /*if (!this.tenant.setupWizardCompleted.value) {
                const component = await import("../components/tenant-setup");
                const modal = new component.default({ data: this.tenant }).initialize();
                await modal.open();
            }*/

            this.dispatchEvent(new CustomEvent("eb:session.login"));
            await this.changeTenant(new URL(window.location.href).searchParams.get("t") || sessionStorage.getItem("t") || this.account.tenants[0].id);

            if (!this.account.setupComplete) {
                const component = await import("../components/account-setup");
                const modal = new component.default().initialize();
                await modal.open();
            }

            if (this.account.passwordExpired) {
                const component = await import("../modals/change-password");
                await (new component.ChangePasswordModal(true));
            }

            this.resetInactivityTimer();

            await PubSubClient.connect(this.#me._links.realtime);
            return data;
        } catch (error) {
            throw error;
        } finally {
            this.dispatchEvent(new CustomEvent("eb:session.requestend"));
        }
    }

    async loginWithSSO(method, code) {
        this.dispatchEvent(new CustomEvent("eb:session.requeststart"));

        try {
            const response = await fetch(new URL(`/api/v3/oauth2/${method}/callback`, this.#baseUrl), {
                method: "POST",
                cache: "no-cache",
                body: JSON.stringify({
                    code: code
                })
            });
    
            if (response.status == 401) throw new Error((await response.text()) || "invalid credentials", { cause: response });
            if (response.status != 201) throw new Error((await response.text()) || response.statusText, { cause: response });
                
            const data = await response.json();

            if (!!data.v2 || response.headers.has("location")) {
                window.location.href = data.v2 || response.headers.get("location");
                return;
            }

            this.#accessToken = data.accessToken;
            localStorage.setItem("at", this.#accessToken);
            this.#accessTokenExpires = data.accessTokenExpires;
            localStorage.setItem("ate", this.#accessTokenExpires);
            this.#refreshToken = data.refreshToken;
            localStorage.setItem("rt", this.#refreshToken);
            this.#refreshTokenExpires = data.refreshTokenExpires;
            localStorage.setItem("rte", this.#refreshTokenExpires);
            this.#me = await this.query(`/api/v3/me`);

            /*if (!this.tenant.setupWizardCompleted.value) {
                const component = await import("../components/tenant-setup");
                const modal = new component.default({ data: this.tenant }).initialize();
                await modal.open();
            }*/

            this.dispatchEvent(new CustomEvent("eb:session.login"));
            await this.changeTenant(new URL(window.location.href).searchParams.get("t") || sessionStorage.getItem("t") || this.account.tenants[0].id);

            if (!this.account.setupComplete) {
                const component = await import("../components/account-setup");
                const modal = new component.default().initialize();
                await modal.open();
            }

            /*if (this.account.passwordExpired) {
                const component = await import("../modals/change-password");
                await (new component.ChangePasswordModal(true));
            }*/

            this.resetInactivityTimer();

            await PubSubClient.connect(this.#me._links.realtime);
            return data;
        } catch (error) {
            throw error;
        } finally {
            this.dispatchEvent(new CustomEvent("eb:session.requestend"));
        }
    }

    async logout() {
        try {
            if (this.#accessToken != null) {
                await fetch(new URL("/api/v3/logout", this.#baseUrl), {
                    method: "POST",
                    cache: "no-cache",
                    headers: {
                        "Authorization": `Bearer ${this.#accessToken}`
                    },
                    body: JSON.stringify({})
                });
            }
        } catch (error) {
            console.error(error);
        } finally {
            this.#accessToken = null;
            localStorage.removeItem("at");
            this.#accessTokenExpires = null;
            localStorage.removeItem("ate");
            this.#refreshToken = null;
            localStorage.removeItem("rt");
            this.#refreshTokenExpires = null;
            localStorage.removeItem("rte");
            sessionStorage.removeItem("t");
            this.#me = null;
            this.#tenant = null;
            this.resetInactivityTimer();
            PubSubClient.disconnect();
            this.dispatchEvent(new CustomEvent("eb:session.logout"));
        }
    }
    
    async recoverPassword(email) {
        this.dispatchEvent(new CustomEvent("eb:session.requeststart"));
        try {
            const response = await fetch(new URL("/api/v3/me/recover", this.#baseUrl), {
                method: "POST",
                cache: "no-cache",
                body: JSON.stringify({
                    type: "account",
                    data: { email: email }
                })
            });
    
            if (response.status >= 400) throw new Error((await response.text()) || response.statusText, { cause: response });
            if (response.status == 204) return response.text();
            if (response.status == 205) return null;

            return (await response.json());
        } catch (error) {
            throw error;
        } finally {
            this.dispatchEvent(new CustomEvent("eb:session.requestend"));
        }
    }

    async query(opts) {
        if (typeof opts == "string") opts = { url: opts };
        
        if (opts.fireRequestEvent != false) this.dispatchEvent(new CustomEvent("eb:session.requeststart"));

        try {
            var response = await this.#query(opts);
            if (response.status >= 500) {
                throw new Error((await response.text()) || response.statusText, { cause: response });
            } else if (response.status >= 400) {
                if (response.status == 440) {
                    this.logout();
                    LZSPARouter.navigate("/login");
                }
                throw new Error((await response.text()) || response.statusText, { cause: response });
            } else if (response.status == 204) {
                return response.text();
            } else if (response.status == 205) {
                return null;
            } else if (response.status == 201) {
                if (parseInt(response.headers.get('Content-Length')) == 0) return {};
                return (await response.json());
            } else {
                return (await response.json());
            }
        } catch (error) {
            //if (error.message) showErrorToast(error.message);
            throw error;
        } finally {
            if (opts.fireRequestEvent != false) this.dispatchEvent(new CustomEvent("eb:session.requestend"));
        }
    }

    async #query(opts) {
        return await fetch(new URL(opts.url, this.#baseUrl), {
            method: opts.method || "GET",
            headers: {
                ...(opts.headers || {}),
                "Authorization": `Bearer ${this.#accessToken}`,
                "EB-Tenant": this.#tenantId || ""
            },
            ...(opts.body == undefined ? {} : { body: opts.raw == true ? opts.body : JSON.stringify(opts.body) })
        });
    }

    async post(url, data) { return this.query({ url: url, method: "POST", body: data }); }

    async create(url, data) { return this.post(url, data); }

    async get(url) { return this.query({ url: url }); }

    async put(url, data) { return this.query({ url: url, method: "PUT", body: data }); }

    async update(url, data) { return this.put(url, data); }

    async patch(url, data) { return this.query({ url: url, method: "PATCH", body: data }); }

    async partialUpdate(url, data) { return this.patch(url, data); }

    async delete(url) { return this.query({ url: url, method: "DELETE" }); }

    async upload(url, data) { return this.query({ url: url, method: "POST", headers: { "Content-Type": "application/octet-stream" }, body: data, raw: true, fireRequestEvent: false }); }

    async download(url) { return this.#query({ url: url, method: "GET", raw: true }); }
}

export const EBSession = new EBSessionHandler();